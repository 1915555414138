.chosen-container {
    font-size: $font-size-base !important;
}

ul.chosen-choices .search-choice {
    width: 100%;
}

.chosen-container-multi .chosen-choices {
    padding: 7px !important;
    box-shadow: none !important;
    background-image: none !important;
    border: 1px solid $grey-neutral !important;
}

.chosen-container-multi .chosen-choices .search-choice,
.chosen-container-multi .chosen-choices .search-field,
.chosen-container-multi .chosen-choices .search-field input {
    width: 100% !important;
}

.chosen-container-multi .chosen-choices .search-field input {
    height: auto !important;
    font-size: $font-size-small !important;
}

.chosen-container-multi .chosen-choices li.search-choice {
    background-image: none !important;
    margin: 0 0 5px 0 !important;
    padding: 5px 25px 5px 10px !important;
    border-radius: 0 !important;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 15px !important;
    right: 5px !important;
}

span.select2.select2-container + .show-tick {
    display: none;
}

.select2.select2-container {
    width: 100% !important;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;

    .select2-selection {
        border: 0;

        li.select2-selection__choice {
            display: inline-block;

            &:not(:empty) {
                background: $fl_gray_light-10;
                padding: 4px 12px;
                margin: 4px 2px;
                border-radius: 1.25rem;
                font-size: 13px;
                color: $fl_gray_base;
                border: none;
            }

            &.tag-primary{
                background: $fl_primary_light-10;
            }
        }
    }
}

.rotate30 {
    transform: rotate(30deg)
}

.select2.select2-container .select2-selection li.select2-selection__choice .top_skills_pin{
    margin-right: 8px;
    margin-top: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    float: right;
    color: $fl_gray_base;;
    margin-right: 0;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    overflow: auto;
    position: relative;
}

span.selection > span > ul > li.select2-search.select2-search--inline {
    width: 100% !important;
    position: sticky;
    bottom: 0;
    background: $white;

    > input {
        width: 100% !important;
        border: 1px solid $input-border;
        box-shadow: $shadow-field;
        border-radius: $input-border-radius;
        padding: 5px;
        padding-left: 10px;

        &:focus{
            border-color: $input-border-focus;
            box-shadow: $shadow-field-focus;
        }
    }
}

.select2-dropdown {
    border: 1px solid $input-border;
}