.image-section {
	position: absolute;
	margin-left: -170px;
}

.profile-img {
	float: right;
	margin-left: 20px;
	max-width: 120px;
	width:30%;
	margin-bottom:10px
}

.profile-left-image {
	width: 150px;
	vertical-align: top;
	float: left;
	margin-right: 20px;
	position: relative;
	
	img {
		border: 1px solid $grey-neutral;
		padding: 3px;
		width: 100%;
		height:auto
	}
}

.profile-right-content {
	width: calc(100% - 170px);
	float: left;
}

@media (max-width:960px) {
	.image-section {
		position: relative;
		margin-left: 0;
	}
}

.profile-references .panel-heading .glyphicons-ok {
	color: $brand-primary;
}

.profile-references .panel-heading:hover .far{
	color: $white;
}

.profile-references .panel-default {
	border: 1px solid $grey-neutral;
}

.profile-search p .far{
	margin: 1px 5px 0 0;
}

#zip_autocompletion {
    z-index: 100;
    position: absolute;
    background-color: $white;
    border: 1px solid $grey-neutral;
    width: 250%;
    height: 100px;
    overflow-y: scroll;
    margin-top: 2px;
    padding: 5px;
}

.reference-document {
  	padding: 2px 33px;
  	background-color: $grey-neutral;
}

.profile-step-not-finished {
  	color: $brand-warning !important;
  	margin-top: -2px;
}

.panel.profile-references .panel-body h4 {
  	margin: 10px 0;
  	border-bottom: 1px solid $grey-neutral;
}

#photo-form-ajax {

	#photo {
		position: absolute;
		top: -10000px;
	}

	.border-between > [class*='col-']:before {
		background: $grey-neutral-light;
		bottom: 0;
		content: " ";
		left: 0;
		position: absolute;
		width: 1px;
		height: 335px;
		top: 10px;
	}
	.border-between > [class*='col-']:first-child:before {
		display: none;
	}

}

.introjs-helperNumberLayer {
	background: $brand-primary linear-gradient(to bottom, $brand-primary 0%, $brand-primary-dark 100%) repeat scroll 0 0;
}
.introjs-button {
	color: $white;
	font-size: 12px;
	background: $brand-primary;
	border: none;
	border-radius: 2px;
	text-shadow: none;

	&:hover {
		color: $white;
		background: $grey;
	}

	&.introjs-skipbutton {
		float: left;
		color: $grey;
		background: $grey-neutral-light;
	
		&.introjs-donebutton {
			float: right;
			margin-left: $gap-extra-small;
			color: $white;
			background: $brand-primary;
		}
	}
	&.introjs-prevbutton{
		&.introjs-fullbutton {
			color: $grey;
			background: $grey-neutral-light;
			border: none;
			border-radius: 2px;
		}
	
		&:hover {
			color: $white;
			background: $grey;
		}
	}
	&.introjs-nextbutton {
		margin-left: $gap-extra-small;
	}
}



@media (max-width: 800px) {

	.single-online-profile {
		margin-right: 20px;
		width: calc(50% - 20px) !important;
	}

	.panel-body .single-online-profile:nth-child(4n) { margin-right: 20px}

	.panel-body .single-online-profile:nth-child(3n+1) { margin-right: 20px}
}

@media (max-width: 480px) {

	.single-online-profile {
		margin-right: 0;
		width: 100% !important;
	}

	.panel-body .single-online-profile:nth-child(4n) { margin-right: 20px}

	.single-online-profile .freelancer-image .media-left .media-object { width:40px !important;}

	.profile-left-image {
		width:20%;
	}

	.profile-right-content {
		width: calc(80% - 20px);
	}
}

.text-location {
	width: 88%;
	display: inline-block;
}

.btn-booking {
	a {
		color: $white !important;
	}
}

.btn-contact {
	border: 1px solid $brand-primary;
	background-color: $white;
	color: $brand-primary;
}

.select-candidate.selected {
	border: 1px solid $brand-primary;
	box-shadow: 0 2px 6px $brand-primary;
}