
.rating {
  text-align: center;
  margin: 20px 0;

  .star {
    font-size: 31px;
    color: $grey-neutral;
  }

  &.rating-1 {
    .star-1 { color: $brand-primary; }
  }

  &.rating-2 {
    .star-1 { color: $brand-primary; }
    .star-2 { color: $brand-primary; }
  }

  &.rating-3 {
    .star-1 { color: $brand-primary; }
    .star-2 { color: $brand-primary; }
    .star-3 { color: $brand-primary; }
  }

  &.rating-4 {
    .star-1 { color: $brand-primary; }
    .star-2 { color: $brand-primary; }
    .star-3 { color: $brand-primary; }
    .star-4 { color: $brand-primary; }
  }

  &.rating-5 {
    .star-1 { color: $brand-primary; }
    .star-2 { color: $brand-primary; }
    .star-3 { color: $brand-primary; }
    .star-4 { color: $brand-primary; }
    .star-5 { color: $brand-primary; }
  }
}