#profile_edit {
  .link {
    word-break: break-all;
  }

  @media (max-width: 991px) {
    #profile_actions {
      text-align: left
    }
  }
}

.photo-edit {
  position: relative;
  display: inline-block;
  cursor: pointer;

  img {
    width: 140px;
    height: 140px;
    border: 1px solid $grey-neutral;
    margin-bottom: 1rem;
  }

  &:not(.edit-logo) img{
    border-radius: 100%;
  }

  &:hover > i {
    display: block !important;
  }

  .remove-photo-icon {
    display: none;
    position: absolute;
    left: 5px;
    bottom: 21px;
    top:unset;
    padding: 4px 6px;
    background-color: $white;
    border: 1px solid $brand-danger;
    border-radius: 50%;
    color: $brand-danger;
    z-index: 2;
  }

  .edit-photo-icon {
    display: none;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 5px;
    background-color: $white;
    border: 1px solid $brand-primary;
    border-radius: 50%;
    color: $brand-primary;
    z-index: 2;
  }

  @media (max-width: 767px) {
    .remove-photo-icon, .edit-photo-icon {
      font-size: 26px;
    }
  }
}

.croppie-container {
  padding-top: 0;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: $white;
  background-color: $brand-warning;
}
