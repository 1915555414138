.modules-services {

	.icon-success {
		color: $brand-primary;
	}

	tr > td {
		padding: 7px !important;
		line-height: normal !important; //reset to default
	}

	tr > td:not(:first-child), tr > th:not(:first-child) {
		text-align: center !important;
	}

	tr.space > th, tr.space > td {
		border-top: 26px solid $grey-neutral-light !important;
	}

	tr.zeroHeight > td {
		padding: 0px !important;
		line-height: 0 !important;
	}

}

