.statistic{
  margin-top: 5px;

  .overlay-div {
    position: relative;
  }

  .overlay-image {
    position: absolute;
    top: -100px;
    left: 0px;
    z-index: 100;
    border: 1px solid $grey-neutral;
  }

  .media, .media-body {
    overflow: visible;
  }

}