.social-box img {
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 3px;
  max-width: 35px;
  width: calc(20% - 20px);
}

.three-boxes {
  border: 1px solid $brand-primary;
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  min-height: 163px;
}

footer{
  padding-top: 1rem;
  border-top: 5px solid $black;
  margin-top: 2rem;
  text-align: center;

  &.footer-lite{
    border:none;

    ul li{
      display: inline-block;
    }
  }

  .h3{
    font-size: 1.125rem;
    font-weight: 600;
    margin: 1.5rem auto .25rem auto;
    color: $black;
  }

  ul {
    a{
      color: $grey;
      font-size: .875rem;

      &:hover,
      &:focus{
        color: $fl_primary_dark-60;
      }
    }
  }

  .footer-social i {
    color: $black;

    &:hover{
      color: $brand-primary-dark;
    }
  }

  .footer-copyright{
    margin-top:1rem;
    font-size: .875rem;
    color: $grey;

    p{
      font-weight: 400;
    }
  }
}

@include respond-to(small){
  footer {
    text-align: left;

    .col-footer{
      width: 33%;
    }

    .legal{
      text-align: right;
    }

    .footer-social {
      text-align: right;

      i {
        margin:.25rem;
      }
    }

    .footer-copyright {
      margin-top: 0;
    }
  }
}

@include respond-to(medium){
  footer {
    .col-footer{
      width: 20%;
    }
  }
}

.footer-lite {
  margin-top:-20px;
  .left-content {
    text-align:left;
    i {
      color: $brand-primary
    }
    a {
      margin-right:20px
    }
  }
  .right-content {
    text-align:right;
    p {

    }
  }
}

@media (max-width: $screen-sm) {
  .footer-lite .left-content,
  .footer-lite .right-content {
    text-align: center;
  }
}

/*Go to top button*/
.cd-top {
  display: block;
  background: $brand-primary;
  color: white!important;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  box-shadow: $shadow-primary;
  border-radius: 50%;
  position: fixed;
  bottom: 100px;
  right: 1rem;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  z-index:100;

  &:hover{
    background: $brand-primary-dark;
  }
}

.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
  visibility: visible;
  opacity: 1;
}
.cd-top.cd-fade-out {
  opacity: 1;
}
.no-touch .cd-top:hover {
  background-color: $brand-secondary;
  opacity: 1;
}

