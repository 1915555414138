// PROGRESS CIRCLE
.c100 {
  float: none;
  margin: 0 auto;
  background-color: $grey-neutral-light;
  transition: all 0s;

  &:after {
    background-color: $white;
  }

  span {
    color: $black;
    font-size: 30px;
    width: 100%;
    line-height: 130px;

  }

  .fill, .bar, .pie {
    border-color: $brand-primary !important;
  }
}

.c100:hover {
  cursor: default;
}

.c100:hover > span {
  width: 100%;
  line-height: 130px;
  font-size: 30px;
  color: $black;
}

.c100:hover:after {
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
}

