.form-search .search-radius {
    width: 50% !important;
}

.form-search input {
    width: 100% !important;
}

.search-input-inline {
    padding: 0 2px 0 0;
}

.project-detail-title {
    color: $fl_primary_dark-40;
    font-size: 16px;
}

.city-autocompletion {
    z-index: 100;
    border-radius: 5px;
    max-height: 100px;
    overflow-y: scroll;
}

.city-autocompletion a {
    color: $black;
}

.fl-form-row .city-autocompletion {
    position: absolute;
    margin-top: -7px;
    width: 350px;
}

#project_city_autocompletion,#zipcode_city_autocompletion {
    position: absolute;
    max-height: 200px;
    margin-top: -7px;
    width:100%;
}

.autocomplete {
    -webkit-box-shadow: $shadow-primary;
    box-shadow: $shadow-primary;
}

.autocomplete {
    background-color: $white;
    padding: 5px 10px;
    margin-top: 7px;
    z-index: 200;
    border-radius: 5px;
    max-height: 100px;
    overflow-y: scroll;
    color: $black;
    cursor: pointer;
}

.autocomplete div {
    padding: 5px;
}

.autocomplete div:hover{
    background-color: $brand-primary;
    color: $white;
}

#project-search-extended + div.form-row {
    margin-top: 20px;
}

#searchagent-save-box {
    height: 50px;
}

#searchagent-save-box .far {
    margin-top: -5px;
    margin-right: 5px;
}

#searchagent-save-box .text {
    margin: 16px 0;
}

#searchagent-save-box .button {
    margin: 11px 0;
}

/*#__search .form-row div {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}*/

/*#__search .bootstrap-select button {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}*/

.nav-tabs.filter {
    margin: 10px 0;
}

#project-search-extended {
    margin-top: 15px;
}

#pagination {
    margin-bottom: 10px;
}

#pagination > p {
    margin-top: 5px;
}

.project-detail-title {
    color: $fl_primary_dark-40;
    font-size: 20px;
    line-height: 36px;
}


.single-profile {
    display: table;
    width: 100%;
}

.single-online-profile { border: 1px solid $grey-neutral }

.media-left {
    position: relative;}

.with-border img.media-object {
  border: 1px solid $grey-neutral;
  padding:5px
}

.data-security,
.related-item img.data-security {
      position: absolute;
      left: -9px;
      top: -3px;
      width: 20px !important;
      height: auto !important;
      border: none !important;
    padding: 0 !important;
}

.verified { left: -7px;
    position: absolute;
    top: -4px;}

.avatar .far.fa-fingerprint {
    background: $white none repeat scroll 0 0;
    border: 1px solid;
    border-radius: 50%;
    padding: 5px;
    color: $brand-primary;
}

.freelancer-image .media-left { padding-right: 0}

.freelancer-content {
    display: table-cell;
    padding-right: 15px;
    vertical-align: middle;
    width: 520px;
}

.freelancer-online-content { width:400px}

.freelancer-content h3 {
    font-size: 20px;
    margin-bottom: 8px;

    a {
        font-weight: 700;
        color: $fl_primary_dark-40;

        &:visited {
            color: $fl_primary_dark-20;
        }

        &:hover {
            color: $fl_primary_dark-60;
        }
    }
}

.freelancer-details {
    display: table-cell;
    vertical-align: middle;
    padding-right:15px
}

.freelancer-descr {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 2px;
}

.freelancer-more { margin-bottom: 7px}

.freelancer-descr > span {
    color: $grey;
    display: inline-block;
    margin-bottom: 2px;
}

.freelancer-action {
    width: 24px;
    display: table-cell;
    position: relative
}

.profile-search p .far {
    margin: 3px 4px 0 0;
}

.project-detail-status {
  color: $black;
  font-size: 13px;
  line-height: 20px;
  max-width: 55%;
    &_searchagent > *{
        display: flex;
        &.project-detail-status__availability {
            align-items: flex-start;
            &>.fa-clock-o {
                min-width: 20px;
                max-width: 20px;
                width: 20px;
                height: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-right: 3px;
            }
        }
        .project-detail-status_searchagent--icon {
            min-width: 20px;
            max-width: 20px;
            width: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: 3px;

            .far {
                position: initial;
            }
        }
    }
}

.project-detail-status i,
.details-box i {
    color: $grey;
    width:20px
}

.project-detail-status .fg-green-light {
    color: $brand-success-light;
}

.project-detail-status .fg-grey-light {
    color: $grey-light;
}

.freelancer-extra-info {
    padding: .375rem 1rem;
    color: $grey;
    font-size: .75rem;
    margin-top: -.5rem;
}

.modal-content { color: $black}

.sorting button.btn { margin-bottom: 0}

.autocomplete-suggestion {
    background: $white;
    padding: 10px 15px;
}
.autocomplete-suggestion:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.autocomplete-suggestions {
    overflow-y: scroll;
    max-height: 400px;
    background: $white;
    overflow-x: hidden;
}

/* media queries */

@media (max-width: 1024px) {
    .project-detail-status {
        width:100%;
        max-width: 100%;
    }
}

@media (max-width: 960px) {

    .freelancer-content { width:450px }

    .freelancer-online-content { width:300px}

}

@media (max-width: 860px) {

    .freelancer-content { width: 380px}
    .freelancer-online-content { width:200px}
}


@media (max-width: 800px) {

    .freelancer-content { width:300px }
    .freelancer-online-content { width:200px}
    /*.freelancer-image { vertical-align: top }*/

}

@media (min-width: 768px) {

    .searchfield  .sorting  { padding:0; margin-bottom:0 !important }

    .searchfield  .select-for-premium:last-child .sorting  { margin-bottom:0 !important }

    .filters-section button {
        width: calc(100% - 2px) !important;
        margin-bottom: 0 !important;
        padding-left:10px
    }

}

@media (max-width: 767px) {

    .searchfield  .sorting  { padding:0; margin-bottom:10px !important }

    .searchfield  .select-for-premium:last-child .sorting  { margin-bottom:0 !important }

    .big-black-button { margin-top:0 !important }

    .filters-section button {
        width: calc(100% - 2px) !important;
        margin-bottom: 0 !important
    }

}


@media (max-width: 600px) {

    .freelancer-image,
    .freelancer-content,
    .freelancer-details { display: block}

    /*.freelancer-image {
        float: left;
        vertical-align: top;
    }*/

    .freelancer-content {
        float: left;
        padding-right: 30px;
        width: calc(100% - 95px);
    }

    .freelancer-content h3 { font-size: 19px }

    .freelancer-details {
        clear: both;
        padding-top: 15px;
    }

    .project-detail-status { margin-bottom:0}


}


@media (max-width: 480px) {

    .freelancer-content {
        width: calc(100% - 65px);
    }

    .freelancer-details {
        clear: both;
        padding-left: 65px;
        padding-top: 7px;
    }

}


@media (max-width: 380px) {

    .freelancer-content {
        padding: 0;
        width: 100%;
    }

    .freelancer-details {
        padding-left: 0;
    }

}

// project sorting case

.project-sorting-pagination {
    position: relative;
    min-height:40px;
}

.project-sorting {
    max-width:230px;
    @include respond-to(small){
        position: absolute;
        right: -11px;
        top: -7px;
    }
}

#projects_filter_options .form-group {
    margin-bottom: 0
}

#projects_filter_options .panel-body .far {
    top: 1px;
}

@media (min-width: 768px) {
    .project-pagination #pagination > p {
        position: absolute;
    }
}