.searchagent{
  .form-editable .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width:calc(100% - 25px);
    margin-bottom: .5rem;
    display: inline-block;
    float: left;
  }

  .selectarea-category-children.btn-group{
    margin-bottom: 1.5rem!important;
  }

  .icon-category-box{
    float: left;
  }

}