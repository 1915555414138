$black-light: #333;
$font-size-giant: 26px !default;

.primary-color { //NS-TO-DO - WHy showing yellow?
  color: $brand-primary !important;
}

.warning-color {
  color: $brand-warning !important;
}

.yellow {
  color: $brand-info !important;
}

.green {
  color: $brand-success!important;
}

.white {
  color: $white !important;
}

.green-regular {
  color: $brand-success !important;
}

.green-dark {
  color: $brand-success-dark !important;
}

.green-eye-open {
  color: $brand-success;
}

.gray {
  color: $grey !important;
}

.grey {
  color: $grey;
}

.red {
  color: $brand-danger !important;
}

.gray-light {
  color: $grey-neutral-light !important;
}

.brand-color {
  color: $brand-primary !important;
}

.text-color {
  color: $text-color !important;
}

.glyphicons-default {
  top: 1px !important;
}

.glyphicons-minus1px {
  top: -1px !important;
}

.gradient-white-grey {
  background: $white; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient($white, $grey-lighter); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($white, $grey-lighter); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($white, $grey-lighter); /* For Firefox 3.6 to 15 */
  background: linear-gradient($white, $grey-lighter); /* Standard syntax */
  border: 1px solid $grey-neutral-light;
  border-radius: 4px;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.bottom-separator {
  margin-bottom: 20px !important;
}

.black-link {
  color: $black;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
   margin-top: 0 !important;
 }
.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}

.no-border {
  border: 0 !important;
}

.standard-border {
  border: 1px solid $grey-neutral;
}

.standard-padding-right {
  padding-right: 12px;
}

.standard-padding-left {
  padding-left: 12px;
}

.standard-padding-top {
  padding-top: 12px;
}

.standard-padding-bottom {
  padding-bottom: 12px;
}

.panel-body-border {
  border-right: 1px solid $grey-neutral;
  border-left: 1px solid $grey-neutral;
  border-bottom: 1px solid $grey-neutral;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

}

.panel-body-border-all {
  border: 1px solid $grey-neutral;
  border-radius: 4px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-top3 {
  margin-top: 3px;
}

.margin-top5 {
  margin-top: 5px;
}

.margin-top8 {
  margin-top: 8px;
}

.margin-top10 {
  margin-top: 10px;
}

.margin-top15 {
  margin-top: 15px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-top40 {
  margin-top: 40px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.icon-size-medium .glyphicons {
  font-size: 20px;
}

button.close {
  text-shadow: none !important;
}

.relative-position {
  position: relative;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-notallowed {
  cursor: not-allowed;
}

// fix for the bootstrap issue with popup
body.modal-open {
  overflow-y: hidden;
}

.no-color {
  color: inherit;
}

p {
  margin: 0 0 calc($line-height-computed / 1.5);
}

.bigger {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}

.border-default {
  border: 1px solid $grey-neutral !important;
}

.blue-border-panel {
  border: 1px solid $brand-primary !important;
}

.border-bottom-default {
  border-bottom: 1px solid $grey-neutral !important;
}

.bottom-standard-margin {
  margin-bottom: 15px !important;
}

.top-standard-margin {
  margin-top: 15px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.font-small {
  font-size: $font-size-small;
}

.font-large {
  font-size: $font-size-large;
}

.font-medium {
  font-size: 1rem !important;
}

.font-giant {
  font-size: $font-size-giant;
}

.line-through {
  text-decoration: line-through !important;
}

.underline {
  text-decoration: underline;
}

.display-none {
  display: none;
}

.display-none-important {
  display: none !important;
}

.d-inline {
  display: inline !important;
}


.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}


.gray-background {
  background-color: $grey-neutral-light;
}

.flex-horizontal-centering {
  display: flex;
  justify-content: center;
}

.flex-vertical-centering {
  display: flex;
  align-items: center;
}

@include respond-to(small){
  .flex-horizontal-centering-sm {
    display: flex;
    justify-content: center;
  }

  .flex-vertical-centering-sm {
    display: flex;
    align-items: center;
  }
}

.minw_50 {
  min-width: 50px !important;
}

.minw_100 {
  min-width: 100px !important;
}

.minw_200 {
  min-width: 200px !important;
}

.minw_300 {
  min-width: 300px !important;
}

.minw_400 {
  min-width: 400px !important;
}

.minw_500 {
  min-width: 500px !important;
}

.maxh_50{
  max-height: 50px!important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom;
}

.transform-uppercase {
  text-transform: uppercase;
}

.fw-normal {
  font-weight: 400;
}

@media (max-width: 991px) {
  .text-sm-left {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .text-xs-left {
    text-align: left;
  }
}

.no-min-height {
  min-height: auto !important;
}

.min-height-250 {
  min-height: 250px !important;
}

.border-dark {
  border: 1px solid grey !important;
}

.btn-red {
  background-color: $brand-danger;
  color: $white;
}

.btn-red:hover {
  background-color: $brand-danger-dark;
}

.btn-green {
  background-color: $brand-success;
  color: $white;
}

.btn-green:hover {
  background-color: $gray-dark;
}

.badge-success {
  background: $green-regular;
}

.badge-lg{
  font-size: inherit;
  padding: 4px 12px;
  border-radius: 20px;
}

// Hover helpers
.hover-shadow{
  &:hover,
  &:focus{
    box-shadow: $shadow-primary;
  }
}

.simple-box {
  border: 0px solid $grey-neutral;
  padding: 10px 10px;
}

.faded-out-box {
  max-height: 50px;
  position: relative;
  overflow: hidden;
}
.faded-out-box .read-more {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  text-align: right;
  margin: 0; padding: 16px 0 0 0;
  background-image: linear-gradient(to bottom,transparent, $grey-neutral-light);
}

.collapse-inline .collapse.in{
  display: inline;
}

.break-word {
  word-break: break-word;
}

.grayed-out * {
  filter: grayscale(.8);
}

.teaser{
  position: relative;

  &::after{
    content: "";
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to top, #FFF, #fff0);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

#reference_category_input.error {
  border: $fl_danger_base 1px solid;
  border-radius: 6px;
}

.file-drop-zone {
  border: 2px dashed $gray-light;
  padding: 20px;
  text-align: center;
  color: $gray;
  background-color: $gray-lighter;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.file-drop-zone.dragover {
  background-color: #FFDE84;
}

.file-for-upload {
  padding: 15px;
  background-color: $gray-lighter;
  border-radius: 6px;
}

.file-upload-error {
  color: $brand-danger;
  font-size: .875rem;
  padding: 2px;
}

.cv-upload-preview {
  max-width: 200px;
}

.cv-upload-spinner {
  width: 128px;
}

.cv-upload-preview-container {
  margin: auto;
  max-width: 200px;
}