.project-detail {

  &.online {

    .project-detail-status em {
      color: $brand-success-light;
    }
  }

  &.unread {

    .project-detail-title {
      font-weight: bold;
    }
  }

  .media-body {
    line-height: 18px;
    vertical-align: middle;
  }
}

.project-detail-status {
  color: $grey;
  display: inline-block;

  @include respond-to(medium) {
    margin-bottom: 0;
  }

  em {
    font-style: normal;
  }
}

.project-detail-location {
  margin-bottom: $gap-width-large * 2;

  @include respond-to(medium) {
    text-align: right;
  }
}

.project-detail-actions {

  @include respond-to(medium) {
    text-align: right;
  }

  li {
    margin-left: $gap-width-small;
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }
  }
}

.project-icon {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: left top no-repeat;
  background-size: 50px 50px;
}

.project-icon-small {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: left top no-repeat;
  background-size: 18px 18px;
  vertical-align: middle;
}

.ex {
  .project-icon,
  .project-icon-small {
    background-image: url('#{$image-path}/freelance-ex.png');
  }
}

.ep {
  .project-icon,
  .project-icon-small {
    background-image: url('#{$image-path}/freelance-ep.png');
  }
}

.sb {
  .project-icon,
  .project-icon-small {
    background-image: url('#{$image-path}/freelance-sb.png');
  }
}

