.project-header h1 {
	font-size: 26px;
}

.project-header .glyphicons {
	font-size: 1.4em;
	margin-right: 5px;
	margin-bottom: 10px;
}

.project-header .media-body {
	padding: 0
}

.project-left-image {
	width: 100px;
	vertical-align: top;
	float: left;
	margin-right: 20px;
	position: relative;

	img {
		border: 1px solid $grey-neutral;
		padding: 3px;
		width: 100%;
		height:auto
	}
}

.project-right-content {
	width: calc(100% - 120px);
	float: left;
}

.content-secondary .action {
	margin-bottom: 5px;
}

.panel.alert-success {
  	@extend .alert-success;
}

.panel.alert-success .fg-black a {
  	@extend .fg-black;
}

.project-location-container-item {
	background-color: $grey-neutral-light;
  	padding: 10px;
  	margin-bottom: 5px;
}

.project-location-container-item:last-child { margin-bottom:20px}

.item-big-bar .project-reference-number {
  	float: left;
  	min-width: 10%;
}

@media (max-width: 767px) {
	.project-export .row {
		display: block;
	}
}

@media (max-width: 480px) {

	.project-left-image {
		width: 20%;
	}

	.project-right-content {
		width: calc(80% - 20px);
	}
}


.owl-carousel {
	display: block;
	width: 100%;
}

.related-item {
	border: 1px solid $grey-neutral;
	padding: .5rem;

	&:hover {
		box-shadow: $shadow-primary;
	}
}
.related-item img {
	display: block;
	float: left;
	height: auto;
	margin-right: 10px;
	width: 75px !important;
}
.related-item h3 {
	color: $fl_primary_dark-40;
	font-size: 18px;
	margin-bottom: 5px;
	word-break: break-all;
}

span.related-content {
	width: calc(100% - 97px);
	float: right;
}

.related-item .project-detail-status {
	line-height: 18px;
	margin-left: 0;
	width:100%;
	max-width: 100%;
}

.owl-theme .owl-nav {
	margin-top: 10px;
	text-align: center;
}
.owl-theme .owl-nav [class*="owl-"] {
	background: $grey-neutral-light none repeat scroll 0 0;
	border-radius: 3px;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	margin: 5px;
	padding: 4px 7px;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
	background: $brand-primary none repeat scroll 0 0;
	color: $white;
	text-decoration: none;
}
.owl-theme .owl-nav .disabled {
	cursor: default;
	opacity: 0.5;
}
.owl-theme .owl-nav.disabled + .owl-dots {
	margin-top: 10px;
}
.owl-theme .owl-dots {
	text-align: center;
}
.owl-theme .owl-dots .owl-dot {
	display: inline-block;
}
.owl-theme .owl-dots .owl-dot span {
	backface-visibility: visible;
	background: $grey;
	border-radius: 30px;
	display: block;
	margin: 5px 7px;
	width: 10px;
	height: 10px;
	opacity: .5;
	transition: opacity 200ms ease 0s;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	opacity: 1;
}

.project-views {
	text-align: left;
}
.project-views h4 { margin-bottom:10px}

.project-views .project-views-scale {
	background: $grey-neutral-light none repeat scroll 0 0;
	color: $white;
	display: block;
	float: left;
	font-size: 10px;
	margin-right: 2px;
	padding: 3px;
	text-align: center;
	width: calc(25% - 2px);
}

.project-views .project-views-scale:last-child  {}

.project-views .project-views-scale.project-views-blue { background: $brand-primary}

ul.project-categories {
	margin-bottom: 10px;
	padding-left: 17px;
}

ul.project-categories:last-child { margin-bottom: 0 }

ul.project-categories li {
	margin-bottom: 3px;
	hyphens: auto;
}

ul.project-categories li:before {
	font-family: 'Font Awesome 5 Pro';
	content: '\f105';
	margin:0 2px 0 -15px;
	color: $fl_primary_light-50;
}

ul.benefits {
	margin-bottom: 10px;
	padding-left: 17px;
	margin-top: 10px;
    overflow: hidden;

    li:before {
        font-family: 'Font Awesome 5 Pro';
        content: '\f00c';
        margin: 0 5px 0 -15px;
        color: $brand-primary;
	}

    li {
        margin-bottom: 5px;
        display: flex;
    }
}

.project-matching-feedback{
	div{
		margin-top: 8px;

		.btn-success{
			margin-right: 6px;
		}
	}

	@include respond-to(medium){
		b{
			position: relative;
			top: 6px;
		}
		div{
			margin-top: 0;
			float: right;

			.btn-success{
				margin-left: 6px;
				margin-right: 0;
				float: right;
			}
		}
	}
}

@media (max-width: 416px) {
	.panel-offered-project-actions {
		position: fixed;
		bottom: 0;
		z-index: 10;
		width: 100%;
		margin-left: -22px;
		background: $white;
		border: 1px solid $grey-neutral;
		margin-bottom: 0;
	}
}

.ai-prompt-position{
	margin-top: -2.875rem;
	position: relative;
	z-index: 1;
}