.abo-create.step-indicator {
  > div {
    background: $grey-neutral;
    padding: .25rem .75rem;
    font-size: .875rem;
    margin-bottom: 2px;

    &.col-sm-15{
      width: 100%;
    }

    &.done{
      background-image: linear-gradient(to right, $brand-primary-light, $brand-primary-light);

      &.right-arrow::after{
        border-color: transparent transparent transparent $brand-primary-light;
      }
    }

    &.current{
      background-image: linear-gradient(to right, $brand-primary, $brand-primary);

      &.right-arrow::after{
        border-color: transparent transparent transparent $brand-primary;
      }
    }

    span {
      display: none;
    }
  }


  @include respond-to(small){
    > div {
      background-image: linear-gradient(to right, $grey-neutral-light , $grey-neutral);
      padding: .5rem 0 .5rem .75rem;
      text-align: center;

      &.col-sm-15{
        width: 20%;
      }

      span{
        display: inline-block;
      }

      &.right-arrow::after {
        border-color: transparent transparent transparent $grey-neutral;
        border-style: solid;
        border-width: 17px;
        content: "";
        display: block;
        height: 0;
        left: 100%;
        margin-top: -17px;
        position: absolute;
        top: 50%;
        width: 0;
        z-index: 100;
      }
    }
  }
}


.total-area {
  border-top: 1px dotted $grey;
  margin-top: 10px;
  padding-top: 20px;
}
img.iban {
  border-radius: 4px;
  height: auto;
  max-width: 100%;
}
.card-container {
  max-width: 350px;
  width: 100%;
}

.form-box-submit-area #next_step {
  float: right !important;
}

#account_bank .radio label div {
    margin: -5px 0 10px 0;
}

#account_bank .radio label div img {
    height: 30px;
}

.mywarning {
    border: 1px solid $brand-danger;
    border-radius: 4px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.warninghead {
    background: $brand-danger none repeat scroll 0 0;
    color: white;
    padding: 15px;
}
.warninghead h3 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}
.warninghead h3 span {
    color: $white !important;
}
.warninghead p {
}
.warninghead p:last-child, .warningcontent ul li:last-child {
    margin-bottom: 0;
}
.warningcontent {
    padding: 15px;
}
.warningcontent ul li {
    margin-bottom: 7px;
}
.warningcontent ul li span {
  color: $brand-danger !important;
  font-size: 12px;
  margin-right: 3px;
  margin-top: 3px;
}

.done.right-arrow {
    display: inline-block;
    padding: 15px;
    position: relative;
}

#account_bank .payment-option label div {
  margin: -8px 0 10px;
  display: inline-block;
  vertical-align: middle;
}

#account_bank .payment-option label div img {
  height: auto;
  margin-bottom: 5px;
  max-height: 35px;
  max-width: 100%;
}

#account_bank .payment-option label div img.payment-logo {
  max-width: 160px;
}

#account_bank .payment-option label p {
  color: $grey;
  font-weight: normal;
}
.package-option label,
.payment-option label {
  cursor: pointer;
}

.package-option input[type="radio"] {
  z-index: 1;
  margin: 4px 0 0;
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
}

.package-option span.price {
  font-size: 19px;
  font-weight: 700;
}

.package-option .payment-table tbody{
  display: table;
}

.package-option .payment-table {
  td {
    padding: 5px;
    vertical-align: top;
  };

  td:nth-child(2) {
    text-align: left;
  }
}

.package-option .payment-table tr td:nth-child(2) {
  width: 160px;
}

.package-option .payment-progress {
  width: 100%;
  height: 10px;
  background: $grey-neutral-light;
  margin-bottom: 15px;
}

.package-option .payment-table {
  margin-left: auto;
  margin-right: auto;
}

.package-option .payment-progress-sb {
  height: 10px;
  background: $fl_primary_light-30;
}

.package-option .payment-progress-en {
  height: 10px;
  background: $fl_primary_base;
}

.package-option .payment-progress-enp, .package-option .payment-progress-ex{
  height: 10px;
  background: $fl_secondary_base;
}

.country-holder .bootstrap-select.btn-group {
  margin-bottom: 0;
}

.mylabels {
  text-align: center;
}

.need-it-bold h1 { font-weight: bold}

#payment-element {
  width: 100%;
  max-width: 400px;
  float: none;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $grey-neutral;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
}

#card-holder-name {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 14px;
  height: 41px;
  margin-bottom: 40px;
}

.package-options {
  padding-top: 20px;
  text-align: center;
}

.payment-options {
  padding-top: 32px;
}

.payment-option {
  display: inline-block;
  margin: 0 30px;
  display: block;
}

@include respond-to(small){
  .payment-options {
    text-align: center;

    .payment-option{
      display: inline;
    }
  }
}

@include respond-to(medium){
  .payment-option label {
      min-width:260px
    }
}

.package-option {
  margin: 0 0 60px 0;
  padding:0;
  text-align: center;
  vertical-align: top;

  @include respond-to(small){
    padding-left: 16px;
    padding-right: 16px;
  }
}

.package-option img {
  display: inline-block;
  width: 70px;
}
.package-option h2 {
  font-weight: normal;
}
.package-option h3 {
  font-size: 16px;
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
  max-width: 185px;
}
.package-option p {
  font-weight: normal;
}
.package-option s {
  color: $grey;
  font-size: 15px;
  font-weight: 100;
  letter-spacing: -1px;
}
.package-option span.zzg {
  color: $grey;
  display: block;
  font-size: 13px;
  font-weight: 100;
  margin-bottom: 4px;
  margin-top: 4px;
}
.package-option span.price {
  font-size: 18px;
  font-weight: bold;
}
.package-option span.sale {
  background: $brand-danger none repeat scroll 0 0;
  border-radius: 50%;
  color: $white;
  display: inline-block;
  font-size: 10px;
  height: 23px;
  letter-spacing: -1px;
  line-height: 23px;
  margin-left: 3px;
  margin-top: -1px;
  vertical-align: top;
  width: 23px;
}

.show-all_container {
  .collapse-fade{
    max-height: 60px;
    overflow-y: hidden;

    &::after{
      background-image: linear-gradient(to top, #fff, #fff0);
      display: block;
      position: absolute;
      content: "";
      height: 65px;
      width: 100%;
      bottom: 20px;
      left: 0;

      @include respond-to(medium){
        bottom: 0;
      }
    }
  }

  &+.collapsed,
  .collapsed {
    .fa-anlge-up{
      transform: rotate(180deg);
    }
  }
}

.voucher-input {
  padding-top: 12px;
  height: 134px;
  text-align: left;
}

.certs {
  text-align: left;
}

.certs img {
  display: inline;
  margin-right: 10px;
}

.panel-default.light-panel {
  border-radius: 0;
}

.panel-default.light-panel > .panel-heading {
  background: $grey-neutral-light none repeat scroll 0 0;
  border-radius: 0;
  color: #000;
}

.payment-option input {
  margin-right: 16px;
  opacity: 1;
  vertical-align: super;
}

span.show-iban {
  color: $grey;
  cursor: pointer;
}
span.show-iban:hover {
  color: $brand-primary;
}
span.show-iban img {
  max-width: 350px;
  opacity: 0;
  padding-left: 5px;
  position: absolute;
  transition: all 0.3s ease 0s;
}
span.show-iban:hover img {
  opacity: 1;
}

.sepa-text {
  font-size: 12px;
  line-height: 17px;
  margin-left: 0;
}

.payment-fields { width:100%}

div.cc {
  background-color: $grey-neutral-light;
  border: 1px solid $grey-neutral;
  border-radius: 0;
  margin: 20px auto 30px;
  padding: 20px;
  position: relative;
  width: 100%;
}
div.cc h2 {
  color: $grey;
  font-family: "ff-dagny-web-pro", "Helvetica Neue", Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 0;
  text-align: left;
}
div.cc li {
  margin: 8px 0;
}
div.cc li .help {
  color: $grey-light;
  display: block;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding-top: 14px;
}
div.cc li .help a {
  color: $brand-primary;
}
div.cc label {
  color: $grey;
  display: block;
  font-size: 14px;
  font-weight: 400;
}
div.cc input {
  background-color: $white;
  border: 1px solid $grey-neutral;
  box-sizing: content-box;
  color: $grey;
  display: block;
  font-size: 18px;
  height: 32px;
  padding: 0 5px;
  width: calc(100% - 10px);
}
div.cc input::-webkit-input-placeholder {
  color: $grey-neutral;
}
div.cc input:-moz-placeholder {
  color:$grey-neutral;
  opacity: 1;
}
div.cc input::-moz-placeholder {
  color: $grey-neutral;
  opacity: 1;
}
div.cc input:-ms-input-placeholder {
  color: $grey-neutral;
}
div.cc input:focus {
  outline: 1px solid $brand-primary;
}
div.cc #cc_number {
  background-image: url("/images/page_elements/cc.png"), url("/images/page_elements/cc.png");
  background-position: 2px -121px, 260px -61px;
  background-repeat: no-repeat;
  background-size: 120px 403px, 120px 403px;
  padding-left: 54px;
  width: calc(100% - 59px);
}
div.cc #cc_number.visa {
  background-position: 2px -163px, 260px -61px;
}
div.cc #cc_number.visa_electron {
  background-position: 2px -205px, 260px -61px;
}
div.cc #cc_number.mastercard {
  background-position: 2px -247px, 260px -61px;
}
div.cc #cc_number.maestro {
  background-position: 2px -289px, 260px -61px;
}
div.cc #cc_number.discover {
  background-position: 2px -331px, 260px -61px;
}
div.cc #cc_number.amex {
  background-position: 2px -373px, 260px -61px;
}
  div.cc #cc_number.valid.visa {
  background-position: 2px -163px, 260px -87px;
}
div.cc #cc_number.valid.visa_electron {
  background-position: 2px -205px, 260px -87px;
}
div.cc #cc_number.valid.mastercard {
  background-position: 2px -247px, 260px -87px;
}
div.cc #cc_number.valid.maestro {
  background-position: 2px -289px, 260px -87px;
}
div.cc #cc_number.valid.discover {
  background-position: 2px -331px, 260px -87px;
}
div.cc #cc_number.valid.amex {
  background-position: 2px -373px, 260px -87px;
}


li.vertical > ul > li {
  float: left;
  width: 33.33%;
}

#data_form .error-control:empty {
  display: none;
}

#data_form .error-control {
  background: $brand-danger none repeat scroll 0 0;
  color: $white;
  display: block;
  font-size: 11px;
  margin-bottom: 10px;
  margin-top: -10px;
  padding: 3px 3px 3px 10px;
}

#data_form #cc_input .error-control,
#data_form .payment-fields .error-control {
  background: $brand-danger none repeat scroll 0 0;
  color: $white;
}

#data_form #elv_input .form-group .error-control {
  margin-left: 0;
  margin-top: 7px;
}

#data_form .form-group.error.form-box-element-no-border.form-last-element.required {
  margin-bottom: 0;
}

#data_form #cc_input .select-error { margin-top:0;}

#data_form #cc_input .select-error .error-control { margin-top:0;  margin-left: 0}

#data_form .country-holder .select-error {
  margin-left: -5px;
  margin-top: 10px;
  width: calc(100% - 7px);
}

#data_form #company_box .col-sm-12 { padding-right: 0 }

.package-option label h2 input { opacity: 1}

.payment-safety {
  background: $grey;
  border-radius: 4px;
  color: $white;
  margin-bottom: 20px;
  padding: 8px;
  text-align: center;
}

.payment-safety .far.fa-lock-alt{
  color: $white;
  right: 4px;
  top: 1px;
}



/* media queries */


@media (max-width: 960px) {
  div.certs { display: none }

  .form-box-submit-area { margin-top:30px; text-align:center}

  .form-box-submit-area #next_step,
  .form-box-submit-area #finish_pay_process_now { float: none !important; margin-bottom: 30px}
}

@media (max-width: 480px) {
  .payment-option {
    display: block;
    margin: 0 30px;
  }
}

@media (max-width: 380px) {
  .card-wrapper {
    display: none;
  }
  .payment-option {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    width: 100% !important;
  }

}

.data-policy-company {
  clear: both;

}

.epp {
  .project-icon,
  .project-icon-small {
    background-image: url('#{$image-path}/freelance-epp.png');
  }
}

.agreement-mobile {
  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 15px;
    .text-center {
      text-align: left;
    }
  }
}


#account_bank #data_form label img{
  pointer-events: none;
}