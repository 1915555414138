@media (min-width: 768px) {

  .no-table-cell .sidebar {
    display: inline-block;
    float: left;
  }

  .no-table-cell #js-main {
    display: inline-block;
    float: left;

  }

  .no-table-cell.no-west-navi #js-main {
    width: 100%;
  }

  .minimized .no-table-cell #js-main {
    width: calc(100% - 60px)
  }

  .no-sidebar .no-table-cell #js-main {
    width: 100%
  }

  .content-middle {
    width: calc(100% - 230px);
    display: inline-block;
    float: left;
  }

  .no-table-cell .content-secondary {
    float: left;
    width: 230px;
  }

}

.no-table-cell #js-main {
  width: 100%;

  @media screen and (min-width:768px) {
    width: calc(100% - 48px);
  }

  @media screen and (min-width:1200px) {
    width: calc(100% - 184px);
  }
}


@media (max-width: 767px) {
  .no-padding-left-768 { padding-left: 0 !important}

}