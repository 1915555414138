.radio {
  padding-left: 20px;
}

.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  font-weight: 400;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: -20px;
  top: 4px;
  border: 1px solid $fl_gray_light-30;
  border-radius: 50%;
  background-color: $white;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 10px;
  height: 10px;
  left: -17px;
  top: 7px;
  border-radius: 50%;
  background-color: $grey;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

input[type="radio"] {
  opacity: 0;
  z-index: 1;
}

input[type="radio"]:focus + label::before {
  outline: none;
}

input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

input[type="radio"]:disabled + label {
  opacity: 0.65;
}

input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

input[type="radio"] + label::after {
  background-color: $brand-primary;
}

input[type="radio"]:checked + label::before {
  border-color: $brand-primary;
}

input[type="radio"]:checked + label::after {
  background-color: $brand-primary;
}

input[type="radio"] .styled:checked + label::before {
  color: $white;
}

input[type="radio"] .styled:checked + label::after {
  color: $white;
}
