// clearfix
%clearfix::after {
  clear: both;
  content: '';
  display: table;
}

// border radius with vendor prefixes
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// media-query
@mixin respond-to($breakpoint) {
  $query: map-get($breakpoints, $breakpoint);

  @if not $query {
    @error 'No value found for `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.';
  }

  @media #{if(type-of($query) == 'string', unquote($query), inspect($query))} {
    @content;
  }
}

// color classes
@each $label, $color in $colors {
  .fg-#{$label} {
    color: $color !important;
  }

  .bg-#{$label} {
    background-color: $color !important;
  }
}