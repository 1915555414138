#confirm_reference {
  .content {
    border-radius: 4px;
    background: $white;
    text-align: center;

    h1 {
      padding: 20px 0 10px;
      font-weight: 600;

      i {
        color: $brand-primary;
        margin-right: 5px;
      }
    }

    h2 {
      font-weight: 600;
    }

    hr {
      border-top: 1px solid $grey-neutral
    }

    .modal-link {
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;

      i {
        margin-bottom: 1px;
        vertical-align: middle;
      }
    }

    .fl-form {
      text-align: left;
      position: relative;

      small {
        position: absolute;
        left: 20px;
        bottom: 20px;
      }
    }

    #freelancer-search {
      margin: 40px auto;
      text-align: center;

      form {
        width: 100%;
        max-width: 560px;
        background-color: $brand-primary;
        margin: 10px auto 0;
        padding: 20px;
        border-radius: 4px;

        .row {
          width: 100%;
          max-width: 520px;
          margin: 0 auto;
        }

        .form-control {
          margin: 0;
          border: none;
        }

        button {
          width: 100%;
          height: 35px;
          margin: 0 0 0 4px;
          background: $black;
          border: 2px solid $white;
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: uppercase;
          transition: all 0.3s ease;
          color: $white;

          &:hover {
            background: $fl_primary_dark-60;
          }
        }
      }
    }

    #freelancer-list {
      padding: 20px 10px 0;
      background-color: $grey-neutral-light;
      text-align: left;
    }
  }
}

.large-quote {
  font-size: 27px;
}

.reference-number {
  width: 45%;
  float: right;
  font-size: 13px;
}

@media (max-width: 1024px) {
.reference-number {
    width: 100%;
    margin-top: 5px;
  }
}