body {

  &.modal-open {
     //overflow: scroll;
    overflow-y: auto;
  }
}

.modal {

  overflow: auto;

  .modal-dialog {
    margin: 72px 15px 15px 15px;

    .modal-content {

      .modal-body {
        //max-height: 420px;
        //overflow-y: auto;
      }
    }
  }
}