// PROGRESS BAR
.progress {
  position: relative;
  text-align: center;
  background-color: $fl_gray_light-10;
  margin-bottom: 1.5rem;

  .progress-bar {
    box-shadow: none;
  }

  @include respond-to(medium){
    margin-bottom: 0;
  }
}

// Progress icons
.border-section{
  float: left;
  position: relative;
  text-align: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  transform: rotate(0deg);

  background-color:$fl_primary_base;
  background-image:
          linear-gradient(91deg, transparent 50%, $fl_primary_light-10 50%),
          linear-gradient(90deg, $fl_primary_light-10 50%, transparent 50%);

  i.far{
    color: $fl_primary_light-30;

    &.fa-rocket{
      position: relative;
      top: 1px;
    }
  }
}

.inner-circle{
  width: 22px;
  height: 22px;
  position: relative;
  top: 2px;
  left: 2px;
  text-align: center;
  border-radius: 100%;
  background: #fff;
  transform: rotate(0deg);
}

.progress-milestone{
  width: 28px;
  height: 28px;
  border: 2px solid $fl_primary_light-30;
  border-radius: 100%;
  background: $white;
  position: absolute;
  top: -8px;
  right: -14px;
  z-index: 1;
  text-align: center;

  i.fa-eye,
  i.fa-rocket{
    color: $fl_primary_light-50;
    vertical-align: middle;
  }

  &.done{
    border-color: $fl_primary_base;

    i.fa-eye,
    i.fa-rocket{
      color: $fl_primary_base;
    }
  }
}

.progress-dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  width: 260px;

  @media screen and (max-width:991px){
    position: absolute;
    top: 10px;

    &#two,
    &#three{
      left:-50px;
    }

    &#four{
      left: -220px;
    }
  }

  @media screen and (max-width:500px){
    &#two,
    &#three{
      left:-180px;
    }
  }
}

.progress-pills {
  position: relative;
  display: flex;
  margin-top: 16px;

  .progress-pill {
    display: block;
    height: 1rem;
    margin-right: 8px;
    background: $fl_gray_light-30;
    flex-grow: 1;
    border-radius: 1rem;

    &.active {
      background: $fl_primary_base;
      color: $white;
      text-align: center;
    }
  }
}
