.hidden-600{
  display: none;
}

.list-item-content h3,
.list-item-content h3 a{
  font-weight: 600;

  &.bold{
    //color: $fl_primary_light-50!important;
    font-weight: 900;
  }
}

@media screen and (min-width:600px) {
  .list-item-content{
    display: inline-block;
    width: 100%;

    .freelancer-image {
      display: inline-block;
    }

    .list-item-main {
      width: calc(100% - 2rem);
      float: right;
    }

    .avatar + .list-item-main {
      width: calc(100% - 100px - 1rem);
      float: right;
    }

    .avatar-sm + .list-item-main {
      width: calc(100% - 70px - .75rem);
      float: right;
    }
  }

  .panel-checkbox-box + .list-item-content{
    width: calc(100% - 2.25rem);
  }

  .hidden-600{
    display: inline-block;
  }
}

.panel-checkbox-box {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1rem;
  width: 1.875rem;

  .panel-checkbox{
    margin: 0;

    input[type="checkbox"]{
      top:1.25rem;
      left: 1rem;
    }
  }
}

.company-name{
  color: $grey;
  font-size: .875rem;

  .far {
    margin: 4px 4px 4px 0;
  }
}

.status-on,
.status-off{
  font-size: .75rem;
  display: inline;
  font-weight: 700;
}

.status-off{
  color: $grey;
}

.status-on{
  color: $brand-info-dark;
}

.panel .additional{
  font-size: .875rem;
  margin: 0;
  opacity: .5;
}

.overview{
  margin:.5rem 0;

  @include respond-to(small){
    margin:.5rem 0 0 0;
  }
}

.tag-group{
  margin-top: 8px;
}

.tags,
.icon-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.icon-list li{
  font-size: .875rem;
  color: $grey;
  margin-bottom: 0;

  .far{
    margin: 4px 4px 4px 0;
  }
}

.tags-list,
.tags li{
  display: inline-block;

  &:not(:empty) {
    background: $fl_gray_light-10;
    padding: 4px 12px;
    margin: 4px 2px;
    border-radius: 1.25rem;
    font-size: 13px;
    color: $fl_gray_base;

    &.alert {
      background-color: $fl_secondary_light-50;
    }
  }

  &.tag-double{
    padding: 0;
    margin: 4px 0 4px 2px;
    color: $black;
    background: $fl_primary_light-30;

    .tag-start{
      padding: 4px 6px 4px 12px;
      display: inline-block;
      border-radius: 1.25rem 0 0 1.25rem;
    }

    .arrow{
      border-style: solid;
      border-width: 12px 0 12px 8px;
      border-color: transparent transparent transparent $fl_primary_light-30;
      vertical-align: middle;
      position: absolute;
    }

    .tag-end{
      background: $fl_primary_light-10;
      padding: 4px 12px;
      border-radius: 0 1.25rem 1.25rem 0;
    }
  }

  &.tag-primary{
    background: $fl_primary_light-30;
    color: $black;
  }

  &.tag-outline{
    background: transparent;
    border:1px solid $fl_gray_base;

    &:hover{
      background: $fl_gray_light-10;
      border-color: $fl_gray_light-10;
      cursor: pointer;
    }
  }

  &.tag-off{
    background: transparent;
    border:1px solid $fl_gray_light-10;
  }

  &.tag-on{
    background: $fl_gray_light-10;
  }

  &.tags-placeholder{
    background: transparent;
    width: 0;
    padding: 4px 0;
    margin: 4px 0;
  }
}

.match{
  border: 1px solid $black;

  h3{
    margin-top: 32px;
  }

  .ribbon{
    font-size: 14px;
    background: $fl_success_base;
    color: $white;
    padding: 4px 12px;
    position: absolute;

    &-end{
      border-radius: 50px 0 0 50px;
      top: 48px;
      right: -8px;

      &::before{
        content:"";
        position:absolute;
        border-style: solid;
        border-width: 0 0 8px 8px;
        border-color: transparent transparent transparent $fl_success_dark-40 ;
        right: 0;
        bottom:-8px;
      }
    }
  }

  @media screen and (min-width:600px){
    h3{
      margin-top: 0;
    }
  }
}