.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
  font-size: 15px;
  background: #f8f8f8;
  color: #333;
  box-shadow: 0 0 5px 5px #ebebeb inset;
}
.sidr .sidr-inner {
  padding: 0 0 15px;
}
.sidr .sidr-inner > p {
  margin-left: 15px;
  margin-right: 15px;
}
.sidr.right {
  left: auto;
  right: -260px;
}
.sidr.left {
  left: -260px;
  right: auto;
}
.sidr h1,
.sidr h2,
.sidr h3,
.sidr h4,
.sidr h5,
.sidr h6 {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2RmZGZkZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #dfdfdf));
  background-image: -moz-linear-gradient(#ffffff, #dfdfdf);
  background-image: -webkit-linear-gradient(#ffffff, #dfdfdf);
  background-image: linear-gradient(#ffffff, #dfdfdf);
  font-size: 11px;
  font-weight: normal;
  padding: 0 15px;
  margin: 0 0 5px;
  color: #333;
  line-height: 24px;
  box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.2);
}
.sidr p {
  font-size: 13px;
  margin: 0 0 12px;
}
.sidr p a {
  color: rgba(51, 51, 51, 0.9);
}
.sidr > p {
  margin-left: 15px;
  margin-right: 15px;
}
.sidr ul {
  display: block;
  margin: 0 0 15px;
  padding: 0;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid white;
}
.sidr ul li {
  display: block;
  margin: 0;
  line-height: 48px;
  border-top: 1px solid white;
  border-bottom: 1px solid #dfdfdf;
}
.sidr ul li:hover, .sidr ul li.active, .sidr ul li.sidr-class-active {
  border-top: 0;
  line-height: 49px;
}
.sidr ul li:hover > a,
.sidr ul li:hover > span, .sidr ul li.active > a,
.sidr ul li.active > span, .sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span {
  box-shadow: 0 0 15px 3px #ebebeb inset;
}
.sidr ul li a,
.sidr ul li span {
  padding: 0 15px;
  display: block;
  text-decoration: none;
  color: #333;
}
.sidr ul li ul {
  border-bottom: 0;
  margin: 0;
}
.sidr ul li ul li {
  line-height: 40px;
  font-size: 13px;
}
.sidr ul li ul li:last-child {
  border-bottom: 0;
}
.sidr ul li ul li:hover, .sidr ul li ul li.active, .sidr ul li ul li.sidr-class-active {
  border-top: 0;
  line-height: 41px;
}
.sidr ul li ul li:hover > a,
.sidr ul li ul li:hover > span, .sidr ul li ul li.active > a,
.sidr ul li ul li.active > span, .sidr ul li ul li.sidr-class-active > a,
.sidr ul li ul li.sidr-class-active > span {
  box-shadow: 0 0 15px 3px #ebebeb inset;
}
.sidr ul li ul li a,
.sidr ul li ul li span {
  color: rgba(51, 51, 51, 0.8);
  padding-left: 30px;
}
.sidr form {
  margin: 0 15px;
}
.sidr label {
  font-size: 13px;
}
.sidr input[type="text"],
.sidr input[type="password"],
.sidr input[type="date"],
.sidr input[type="datetime"],
.sidr input[type="email"],
.sidr input[type="number"],
.sidr input[type="search"],
.sidr input[type="tel"],
.sidr input[type="time"],
.sidr input[type="url"],
.sidr textarea,
.sidr select {
  width: 100%;
  font-size: 13px;
  padding: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 10px;
  border-radius: 2px;
  border: 0;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(51, 51, 51, 0.6);
  display: block;
  clear: both;
}
.sidr input[type=checkbox] {
  width: auto;
  display: inline;
  clear: none;
}
.sidr input[type=button],
.sidr input[type=submit] {
  color: #f8f8f8;
  background: #333;
}
.sidr input[type=button]:hover,
.sidr input[type=submit]:hover {
  background: rgba(51, 51, 51, 0.9);
}
