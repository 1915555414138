.panel {
  margin-bottom: $line-height-computed*2;
  border: none;
  box-shadow: none;

  .complete {
    color: $black;
    font-size: 40px;
    text-align: center;
    margin: 20px 0;
  }

  .date {
    color: $brand-success-light;
    font-size: $font-size-h2;
    text-align: center;
    margin: 20px 0;
  }

  .contact-phone {
    color: $brand-primary;
    font-size: 25px;
  }

  .blog-link {
    text-decoration: underline;
    color: $brand-primary;

    &:hover {
      color: $grey;
    }
  }

  .member-saving {
    font-size: 40px;
    font-weight:bold  
  }
}


.panel-body {
  .media {
    margin-top: $line-height-computed;

    &:first-child {
      margin-top: 0;
    }
  }


}

.panel-footer {
  border: none;
  text-align: right;
}

// PANEL PROFILE
.panel-profile {

  li {
    margin-bottom: $line-height-computed;
  }

  .media-left {
    width: 40%;

    img {
      width: 100%;
    }
  }

  .media-body {
    width: 60%;
  }
}


// PANEL INBOX
.panel-inbox {

  .media {
    margin-top: 5px;

    &.inbox-unread {

      a {
        font-weight: bold;
      }
    }
  }
}

.panel-aside-blog {
  border: 1px solid $brand-primary;

  > .panel-heading {
    background-color: $brand-primary !important;
    color: $white !important;
  }

  a {
    color: $brand-primary;

    &:hover {
      color: $brand-primary-dark;
    }
  }

  .blog-entry {
    display: table;
  }

  .blog-link {
    display: table-cell;
    min-width: 70px;
  }

  .blog-subject {
    display: table-cell;
  }
}

.panel-aside-premium {
  border: 1px solid $brand-primary;
  border-radius: 0;

  > .panel-heading {
    background-color: $brand-primary;
    color: $black;
    text-align: center;
  }

  > .panel-body {
    background-color: $brand-primary;
    color: $white;
    text-align: center;

    h3 { font-size: 22px; margin-bottom: 7px }

    a.btn { background: $white; color: $black}

    a.btn:hover { background: $grey; color: $white}

    p { margin-bottom: 11px}

  }

  > .panel-footer {
    background-color: $brand-primary;
    color: $black;
    border-radius: 0;
  }

  a {
    color: $black;

    &:hover {
      color: $grey;
    }
  }
}

.panel-aside-contact {
  border: 1px solid $brand-primary;
  text-align: center;

  > .panel-heading {
    background-color: $white;
    color: $black;
  }
}

.collapsed {
  .set-editable {
    display: none;
  }
}