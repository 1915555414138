form .js-invisible {
  display: none;
}

.form-horizontal {
  .form-group {
    &.select-container {
      margin-left: 0;
      margin-right: 0;
      clear: none;
    }

    &.select-container ~ a .far {
      margin-top: -5px;
    }
  }
}

label{
  font-weight: 600;
}

.form-editable {
  .control-label{
    text-align: left;
  }

  .tooltip-toggle {
    float: right;
  }

  .required .control-label:after {
    content: '*';
    margin-left: 2px;
  }

  .form-group.error {
    .form-control,
    label:before {
      border-color: $brand-danger;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(red($brand-danger), green($brand-danger), blue($brand-danger), .6);
    }

    .error-control {
      color: $brand-danger;
      font-size: 12px;
      margin-left: 5px;
      display: inline-block;
    }
  }

  .required-field {
    &.error {
      &:not(:valid) {
        border-color: $brand-danger;
        outline: 0;
      }
      &:valid {
        & + .required-field__error-message {
          display: none;
        }
      }
    }
    &__error-message {
      color: $brand-danger;
      font-size: 12px;
      margin-top: 3px
    }
  }

  &.form-right .control-label {
    text-align: right;
  }

  .selectarea .btn {
    border-color: $input-border;
    box-shadow: $shadow-field;

    &:focus{
      border-color: $input-border-focus;
      box-shadow: $shadow-field-focus;
    }
  }

  .btn-group.selectarea.disabled {
    display: block !important;
  }

  .form-group {
    .label-container {
      label {
        float: left;
        width: 90%;
      }

      a {
        float: right;
        padding-top: 9px;
      }
    }

  }

  .flex-label {
    .label-container {
      display: flex;
      align-items: center;

      label {
        width: auto;
        margin-right: 10px;
        padding-top: 7px;
      }

      a {
        padding-top: 3px;
      }
    }
  }

  .select-error {
    &_xs-fix {
      @media (max-width: 767px) {
        .select-error {
          margin-top: 8px;
        }
      }
    }
  }

  .bootstrap-select.btn-group {
    margin-bottom: 12px;
  }

}

.form-inline .form-control.selectarea {
  padding: 0;
}

.form-group {
  &.group {
    margin-bottom: 0 !important;
  }

  textarea.form-control {
    margin-bottom: 15px;
  }
}

.form-box-submit-area .btn,
.btn-row-right .btn{
  float: right !important;
  margin-left: .5rem;
  margin-top: .5rem;

  &.float-left{
    float: left !important;
    margin-left: 0;
  }
}

.input-field-description {
	height: $input-height-base;
	padding-top: 8px;
}

// CHECKBOXES
input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  cursor: pointer;

  + label {
    &:before {
      content: '';
      font-size: 1rem;
      line-height: 1;
      vertical-align: middle;
      width: 1.5rem;
      height: 1.5rem;
      background: $white;
      border: 2px solid $fl_gray_light-30;
      display: inline-block;
      margin: .25rem .25rem .25rem 0;
    }
  }

  &:checked {
    + label {
      &:before {
        color: $brand-primary;
        font-family: 'Font Awesome 5 Pro';
        content: '\f00c';
        line-height: 1.5;
        text-align: center;
      }
    }
  }

  &.checkbox-sm,
  &.checkbox-sm + label,
  &.checkbox-sm + label::before ,
  &.checkbox-sm:checked + label::before{
    font-size: .75rem;
    width: 1rem;
    height: 1rem;
    margin: 0;
    line-height: 1.2;
  }
}



input[type="checkbox"].checkbox-outline + label:before {
  border: 1px solid $brand-primary;
}

.checkbox-label {
  margin-left: .25rem;
  vertical-align: middle;
}

.form-control{
  margin-bottom: 0;
  border-color: $input-border;
  box-shadow: $shadow-field;

  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    color: $grey;
  }

  &::placeholder{
    font-style: italic;
  }

  &:focus{
    border-color: $input-border-focus;
    box-shadow: $shadow-field-focus;

    .bg-primary-light &{
      border-color: $fl_primary_base;
    }
  }

  &:focus + .input-group-btn .btn {
    border: 1px solid $input-border-focus!important;
    border-left: none!important;
    outline: 0;
  }
}


// BOOTSTRAP SELECT
.bootstrap-select {
  .btn {
    display: block;
    width: 100%;
    font-size: $font-size-base;
    line-height: 1.2;
    background-color: $input-bg;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;

    &:hover {
      background-color: $input-bg;
    }
  }

  &.open {
    > .btn-default.dropdown-toggle,
    > .btn-default.dropdown-toggle:hover {
      background-color: $white;
      border-color: $grey-neutral;
      box-shadow: none;
      color: $input-color;
    }
  }

  &.btn-group {
    .dropdown-toggle {
      color: $input-color;
      background: $white;
      padding: $padding-base-vertical $padding-base-horizontal;
      height: $input-height-base;;

      &:focus {
        border-color: $input-border-focus;
        box-shadow: $shadow-field-focus;
        outline: 0!important;

        .bg-primary-light &{
          border-color: $fl_primary_base;
        }
      }

      .caret {
        right: 18px;
      }
    }

    .dropdown-menu {
      padding: 0;

      > li {
        margin-bottom: 0;

        > a {
          color: $input-color;

          &:hover {
            background-color: $grey-neutral-light;
          }

          &:focus{
            background-color: $brand-primary-lighter;
          }
        }
      }

      &.open {
        margin-top: -2px;
        border-radius: 0;
        border: 1px solid $grey-neutral;
      }
    }
  }

  .selected {
    outline: 0;
  }
}

.error-border {
  border-color: $brand-danger;
  outline: 0;
}

.fl-form {
  margin: 0 auto;
  padding: 1rem;
  width: 100%;

  &.tight {
    max-width: 600px;
  }

  label {
    line-height: 20px;
    font-size: .875rem;
    font-weight: 600;
    margin-bottom: 0;
    padding: 2px;

    .ex i {
      margin-top: -2px;
    }
  }


  input[type="text"], input[type="password"], input[type="email"], input[type="file"], textarea, input[type="number"], div.textarea-type-box {
    width: 100%;
    padding: $padding-base-vertical $padding-base-horizontal;;
    background-color: $white;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    box-shadow: $shadow-field;

    &::placeholder{
      font-style: italic;
    }

    &:focus{
      border-color: $input-border-focus;
      box-shadow: $shadow-field-focus;
    }

    &.error {
      @extend .error-border
    }

    &:disabled, &.disabled {
      background-color: $grey-neutral-light;
      cursor: not-allowed;
    }
  }

  input[type="text"], input[type="password"], input[type="email"] {
    height: $input-height-base;
  }

  input[type="file"] {
    max-height: $input-height-base;
    padding: 2px 6px;
  }

  input[type="checkbox"] {
    + label {
      padding: 0;
      margin-bottom: 5px;
      background: none;
    }

    ~ .checkbox-label {
      width: auto;
      max-width: 80%;

      + .hint {
        right: auto !important;
        margin-left: 3px;
      }
    }

    &.error {
      + label:before {
        @extend .error-border
      }
      ~ .error-text {
        color: $brand-danger;
        background: transparent;
      }
    }

    &:disabled + label:before {
      background: $grey-neutral-light;
    }
  }

  textarea {
    display: block;
    resize: none;
    min-height: 100px;
    max-width: 100%;
    vertical-align: top; // removes unwanted white space after textarea in some browsers

    & + .bootstrap-maxlength {
      border-radius: 0;
    }
  }

  textarea#description,
  textarea#skills{
    min-height: 164px;
    resize: vertical;
  }

  select {
    display: block !important;
    position: absolute;
    opacity: 0;
    z-index: -9999;

    & + .bootstrap-select.form-control {
      height: $input-height-base;;
      background-color: $white;
      border: none;

      .btn {
        height: 100%;
        border: 1px solid $input-border !important;
        border-radius: $input-border-radius;
        box-shadow: $shadow-field;

        &:focus{
          box-shadow: $shadow-field-focus;
          border: 1px solid $input-border-focus!important;
          outline: 0!important;
        }
      }

      &.error {
        .btn {
          @extend .error-border
        }
      }
    }

    &:disabled + .bootstrap-select.form-control .btn {
      opacity: 1;
      background: $grey-neutral-light;
    }
  }

  i {
    &.hint {
      float: right;
      line-height: 20px;
      color: grey;
      cursor: pointer;

      & + .tooltip .tooltip-inner {
        max-width: 100%;
      }
    }
  }

  .radio-group {
    .fl-form-row {
      margin-bottom: 0;

      .radio {
        margin-bottom: 0;
      }

      &:not(:last-child) .error-text {
        display: none;
      }
    }
  }

  .radio {
    label {
      background: none;

      &:before {
        outline: none !important;
      }
    }

    &.error {
      label:before {
        @extend .error-border
      }
      ~ .error-text {
        color: $brand-danger;
        background: transparent;
      }
    }

    input:disabled + label {
      opacity: 1;

      &:before {
        background: $grey-neutral-light;
      }
    }
  }

  .fl-form-row {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;

    .fl-form-container {
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }

      .btn-group.error {
        border: 1px solid $brand-danger;
      }

      .btn-group {
        margin-bottom: 0;
      }
    }
  }

  .string-edit {
    .no-label{
      height: 8px;
    }

    @media screen and (min-width:560px) {
      display: flex;
      align-items: baseline;

      .string-edit-field {
        margin-left: .25rem;
        flex-grow: 1;
        margin-top: -23px; /* no-label-fix */
      }
    }
  }

  .no-label {
    height: 23px;

    + input[type="checkbox"]{
      top:24px;
    }
  }

  .no-label-fix{
    margin-top: -23px;
  }

  @media screen and (max-width:991px){
    .no-label-fix-mobile{
      margin-top: -23px;
    }
  }

  [id*='category_main_box'] .no-label {
    height: 0;
  }

  .input-group {
    input {
      margin-bottom: 0;
      border-radius: $input-border-radius 0 0 $input-border-radius;
    }

    .input-group-addon {
      border-radius: 0 $input-border-radius $input-border-radius 0;

      &:hover, &:active, &:focus {
        cursor: pointer;
        color: $brand-primary;
      }
    }
    .input-group-addon-control {
      border-radius: 0;
      vertical-align: middle;
      width: auto;
    }

    &.datepicker .error ~ .input-group-addon {
      @extend .error-border
    }
  }

  .error-text {
    color: $brand-danger;
    font-size: .875rem;
    padding: 2px;
  }

  .link {
    line-height: 1;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;

    & + p {
      font-size: 14px;
    }
  }

  svg {
    display: block;
    margin-right: 6px;
    margin-top: 3px;
    visibility: visible;
    width: 25px;
  }

/* hide for IE and Edge*/

 @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   svg {
     display: none !important;
   }
 }

 @supports (-ms-ime-align:auto) {
   svg {
     display: none !important;
   }
 }

 .path {
   stroke-dasharray: 1000;
   stroke-dashoffset: 0;
   &.circle {
     -webkit-animation: dash .9s ease-in-out;
     animation: dash .9s ease-in-out;
   }
   &.line {
     stroke-dashoffset: 1000;
     -webkit-animation: dash .9s .35s ease-in-out forwards;
     animation: dash .9s .35s ease-in-out forwards;
   }
   &.check {
     stroke-dashoffset: -100;
     -webkit-animation: dash-check .9s .35s ease-in-out forwards;
     animation: dash-check .9s .35s ease-in-out forwards;
   }
 }

 .group-error {
   border: 1px solid $brand-danger;
   @extend .error-border;

   .fl-form-row {
     padding: 0 5px;
   }
 }

 @-webkit-keyframes dash {
   0% {
     stroke-dashoffset: 1000;
   }
   100% {
     stroke-dashoffset: 0;
   }
 }

 @keyframes dash {
   0% {
     stroke-dashoffset: 1000;
   }
   100% {
     stroke-dashoffset: 0;
   }
 }

 @-webkit-keyframes dash-check {
   0% {
     stroke-dashoffset: -100;
   }
   100% {
     stroke-dashoffset: 900;
   }
 }

 @keyframes dash-check {
   0% {
     stroke-dashoffset: -100;
   }
   100% {
     stroke-dashoffset: 900;
   }
 }
}

.form-row-sm{
  .form-control,
  .btn-group .btn,
  button,
  .control-label{
    height: 2rem!important;
    font-size: .875rem;
  }
}

.form-row-lg{
  .form-control,
  .btn-group .btn,
  button,
  .control-label{
    height: 3rem;
  }
}

//Filters
.filters-section {
  .btn-group {
    background: transparent;

    .btn {
      border-radius: 99px;

      + .dropdown-menu.open {
        margin-top: 4px;
      }
    }
  }

  li.disabled {
    background: $grey-neutral-light;
    color: $grey;

  }

  @media screen and (max-width: 767px){
    .no-clear{
      float: left;
      width: 100%;
    }
  }

  @media screen and (min-width: 768px){
    .no-clear div{
      margin-top:12px;
    }
  }
}

@media screen and (max-width: 479px){
  #container_bulk_action > div {
    float: none;
    display: inline-block;

    .btn-group {
      margin: .5rem 0;
    }
  }
}


@media (max-width: 992px) {
  .fl-form .fl-form-row .fl-form-container {
    margin-bottom: 10px;
    padding-right: 0;
  }
}

// long dropdown options fix on mobile
@media screen and (max-width:460px) {
  .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  .bootstrap-select.btn-group .dropdown-menu li a span.text {
    font-size:13px;
  }
}

.field-pull-up{
  margin-top:-10px;
}

.password-field-icon {
  position: absolute;
  right: 20px;
  top: 34px;
  z-index: 2;
  color: $fl-gray_light-50;

  .input-box &{
    top: 11px;
  }

  &:hover{
    cursor: pointer;
    color: $fl_gray_base;
  }
}

#reference_category_input {
  .fl-form-row {
    margin-bottom: 0;
  }
}