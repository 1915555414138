.staff-profile-contact {
    min-height: 60px;
}

.staff-statistic .form-horizontal .form-group {
    margin-bottom: 5px;
}

.staff-statistic .form-horizontal .control-label {
    padding-top: 2px;
}


.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}