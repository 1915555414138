html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

a, *:visited, *:focus, *:active, *:hover{
  outline: 0 none !important;
}

a:focus {
  color: $link-color;
}

h1, h2, h3, h4 {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wrapper {
  @extend %clearfix;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $gap-width-small 0 $gap-width-small;
}

.wrapper-lp{
  @extend %clearfix;
  margin: 0 auto;
  padding: 0 $gap-width-small 50px $gap-width-small;
}

.header {
  margin: 1.5rem 0 1rem 0;

  .header-logo {
    float: left;
    margin-right: 3px;
    width: 210px;

    @include respond-to(large) {
      width: 230px;
    }

    img {
      max-width: 250px;
      width: 100%;
      height: auto;
    }
  }

  .nav-top {
    clear: both;

    @include respond-to(small) {
      clear: none;
    }
  }
}

.logo-top {
  float: left;
  margin-right: 10px;
}

.table-layout {
  width: 100%;

  @include respond-to(small) {
    display: table;
  }
}

.table-cell {

  @include respond-to(small) {
    display: table-cell;
    vertical-align: top;
  }
}

.sidebar {
  display: none;

  @include respond-to(small) {
    display: table-cell;
    width: $menu-width-small + $gap-width-small;
  }

  @include respond-to(large) {
    width: $menu-width + $gap-width-small;
    margin-right: $gap-width-small;
  }

  .minimized & {
    width: $menu-width-small + $gap-width-small;
    margin-right: $gap-width-small;
  }
}

.banner {
  @extend %clearfix;
  margin-bottom: $gap-width-small;

  img {
    width: 100%;
    height: auto;
  }
}

.main {
  @extend %clearfix;
  /*@include border-radius($border-radius-base);
  padding: $gap-width-large ($gap-width-large/2);
  background-color: $grey-neutral-light;*/
  padding: 0 0 .5rem 0;
}

.content-secondary {
  width: 100%;

  @include respond-to(small) {
    width: $content-secondary-width + $gap-width-large + calc($gap-width-large/2);
  }
}

.tab-content {
  padding-top: $line-height-computed*2;
}

.footer {
  margin-top: $line-height-computed*2;
  margin-bottom: $line-height-computed*2;
}

// BREAKPOINT BRIDGE FOR JAVASCRIPT

body:before {
  content: 'default';
  display: none;

  @include respond-to(small) {
    content: 'small';
  }

  @include respond-to(medium) {
    content: 'medium';
  }

  @include respond-to(large) {
    content: 'large';
  }
}