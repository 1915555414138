.table {

  > tbody {

    > tr {

      &:first-child {
        > td {
          padding: 0;
        }
      }

      > td {
        line-height: 25px;
        vertical-align: middle;
        padding: $line-height-computed 0 0 0;
        &.inbox-action {

          a + a {
            margin-left: $gap-width-small;
          }
        }
      }
    }
  }

}

.table > tbody > tr > td {
  border: none;
}

td:last-child {
  text-align: right;
}



.inbox-row {
  @extend %clearfix;
  margin-bottom: calc($line-height-computed/2);
  display: table;
}

.inbox-select {
  float: left;
  display: table-cell;
  vertical-align: top;
  padding-right: 15px;
}

.inbox-content {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.inbox-date {
  float: left;
  line-height: $input-height-small;
  vertical-align: middle;
  margin-right: 5px;
}

.inbox-action {
  float: right;
  padding-left: 10px;

  a span {
    display: inline-block;
    line-height: $input-height-small;
    vertical-align: middle;
  }

  a + a {
    margin-left: 5px;
  }
}

.inbox-subject {
  float: left;
  line-height: $input-height-small;
  vertical-align: middle;
}