/*
modal box select styling
*/

#send_project_to_freelancer-form {
  .dropdown-menu, .dropdown-toggle {

    small {
      font-size: 14px;
      color: $grey;
    }
    a.already-sent .text {
      color: $grey;
    }
    a.offline .text {
      color: $brand-danger;
    }
  }
}
