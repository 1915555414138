/**

  This file is used to overwrite cookiebot styling
  This method of styling is recommended in cookiebot documentation if we want to use some default consent with some minor changes

  ------------------------------------------------------------------------
  !!! YOU NEED TO USE !important TO OVERWRITE COOKIEBOT DEFINED VALUES !!!
  ------------------------------------------------------------------------

 */

// dialog container div
#CybotCookiebotDialog {
  border: none !important;
  border-radius: 0px !important;
  box-shadow: $shadow-primary !important;
  width: 95% !important;
  max-width: 1250px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%,-50%) !important;
  margin: 0 0 0 auto !important;
}

// dialog body div
#CybotCookiebotDialogBody {
}

/******************** MAIN PART ********************/

// div container for main part of the consent dialog
#CybotCookiebotDialogBodyContent {

}

#CybotCookiebotDialog a, #CybotCookiebotDialog div, #CybotCookiebotDialogBodyContentControls, #CybotCookiebotDialogBodyContentTitle {
  font-family: "Source Sans Pro", sans-serif !important;
}

#CybotCookiebotDialogBodyContentText a {
  color: $fl_primary_base !important;
  text-decoration: none !important;

  &:hover {
    color: $fl_primary_dark-40 !important;
  }
}

/******************** BUTTONS ********************/

// div containing accept/decline buttons and details link
#CybotCookiebotDialogBodyButtons {
}

// <a> element with accept and decline buttons
#CybotCookiebotDialogBodyButtonDecline, #CybotCookiebotDialogBodyButtonAccept {
  border: none !important;
  padding: 6px !important;
  font-size: 0.75rem !important;
  font-weight: 700 !important;
  color: $white ;
  transition: all $transition-normal !important;
  width: 190px!important;

  &:hover {
    background: $fl_secondary_light-30 !important;
    color: $black !important;
  }
}

#CybotCookiebotDialogBodyButtonDecline {
  background: $gray !important;
}

#CybotCookiebotDialogBodyButtonAccept {
  background: $black !important;
}

// <a> element with details link
#CybotCookiebotDialogBodyButtonDetails {
  color: $fl_primary_base !important;
  text-decoration: none !important;

  &:hover {
    color: $fl_primary_dark-40 !important;
  }
}

/******************** DETAILS SECTION ********************/

// div containing details
#CybotCookiebotDialogDetail {

}

// body of details container
#CybotCookiebotDialogDetailBody {
  max-width: 100% !important;
}