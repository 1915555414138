// STYLEGUIDE
.styleguide {

  .main {
    background-color: $white;
  }

  h1 {
    margin-bottom: $line-height-computed*1.5;
    padding-bottom: $line-height-computed;
    border-bottom: 1px solid $black;
  }

  h2 {
    margin: $line-height-computed 0;
    font-size: 18px;
    background-color: $grey;
    padding: $line-height-computed;
  }

  section {
    padding: $line-height-computed*5;
    margin-bottom: $line-height-computed*2;
    background-color: $grey-neutral-light;
  }
}



