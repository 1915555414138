.modal-backdrop{
  &.in{
    opacity: .65;
    filter: alpha(opacity=65);
  }
}

#fl_modal,
.fl-modal{
  .modal-content{
    border-radius: 0;
    box-shadow: $shadow-primary;
  }

  .modal-header {
    background: none;
    border:none;
    padding: 1rem 1rem 0 1rem;

    .close {
      font-size: 1rem;
      font-weight: normal;
      padding:4px;
      color: $grey;
      opacity: 1;

      &:hover{
        color: $fl_primary_dark-60;
      }
    }

    .modal-title {
      font-size: 1.25rem;
      color: $black;
      text-transform: uppercase;
      padding-bottom: .5rem;
      border-bottom: 3px solid $black;
    }
  }

  .modal-body {
    padding: 1rem;

    .alert {
      margin-bottom: 15px;

      i {
        font-size: 24px;
        padding-right: 10px;
      }
    }
  }

  .modal-footer{
    border: none;
  }

  .modal-message {
    padding: 1rem;
    color: inherit;
    box-shadow: $shadow-primary;
    text-align: right;
    background-color: $white;

    .message-text {
      text-align: center;
      margin-bottom: 20px;

      &::before {
        display: block;
        font-size: 3rem;
        font-weight: 900;
        font-family: $font-icon;
      }
    }

    &.info {
      .message-text::before {
        content: "\f05a";
        color: $brand-primary-light;
      }
    }

    &.success {
      .message-text::before {
        content: "\f00c";
        color: $brand-primary-light;
      }
    }

    &.error {
      .message-text::before {
        content: "\f071";
        color: $brand-danger-light;
      }
    }
  }

  .modal-dialog {
    margin: 0;
  }

  .modal-buttons {
    padding: 15px 15px 0;
    margin: 15px -15px 0;
    border-top: 1px solid $grey-neutral;

    > *:first-child {
      float: left;
    }

    > *:last-child {
      float: right;
    }
  }

  .nav-tabs {
    li {
      &.active a {
        background-color: $grey-neutral-light;
      }

      a {
        padding: 0;
        line-height: 38px;
        border: 1px solid $grey-neutral;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  .tab-content {
    padding: 0;
  }

  //Remove shadow for elements used in other places
  .panel-default{
    box-shadow: none;
  }

  @media only screen and (min-width: 768px) {
    &.modal {
      text-align: center;

      &:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
      }

      .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
      }
    }
  }
}

#message_custom_button {
  display: inline-block;
  margin-left: 4px;
  margin-top: 8px;

  @include respond-to(xsmall){
    margin-top: 0;
  }
}

#fl_profile_tour{
  .btn {
    margin:0 auto 8px auto!important;
    display: block;
    min-width: 200px;
  }

  #message_custom_button {
    display: block;
  }

  @include respond-to(small){
    .btn {
      display: inline-block;
      min-width: auto;
      margin: 0 0 0 8px !important;
    }

    #ok{
      float: right;
    }

    #message_custom_button {
      display: inline-block;
      margin: 0;
    }
  }
}