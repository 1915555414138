// Scalable badge
.badge,
.panel-default > .panel-heading .badge{
  font-size: .75em;
  padding: .25em .5em;
  border-radius: 999px;
  position: relative;
  top: -.2em;
  background-color: $brand-primary;
  min-width: 2em;
  color: $white;
}

.badge-success,
.panel-default > .panel-heading .badge-success {
  background-color: $brand-success;
}

.badge-info,
.panel-default > .panel-heading .badge-info {
  background-color: $brand-info;
}

.badge-warning,
.panel-default > .panel-heading .badge-warning {
  background-color: $brand-warning;
}

.badge-danger,
.panel-default > .panel-heading .badge-danger{
  background-color: $brand-danger;
}

.badge-light,
.panel-default > .panel-heading .badge-light{
  background-color: $fl_gray_light-10;
  color: $fl_gray_base;
}

a.badge:hover,
a.badge:focus{
  box-shadow: $shadow-inset;
}

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: $white;
  background-color: $brand-primary;
}

.top-rated{
  color: $black;
  background-color: $brand-info;
  position: absolute;
  top: auto;
  bottom: -.5em;
  transform: translateX(50%);
  right: 50%;
}