.application  {

  .tab-content {
    background-color: $white;
    padding: 15px;
  }

  .application-item-content-text-box a {
    color: $brand-primary;
  }

  .panel-heading.application-item-large:hover a {
    color: $white;
  }

  .form-box-header h2 {
    border-bottom: 1px solid $grey-neutral;
    margin-bottom: 10px;
  }

  .hr-primary {
    border-top: 1px solid $grey-neutral;
  }

  .hr-gray {
    border-top: 1px solid $grey-neutral;
    margin: 15px auto;
    width: 90%;
  }

  .application-manage-project-headline {
    color: $white;
    background-color: $grey;
    border-color: $grey-neutral;
    padding: 5px 5px 5px 15px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    margin-top: 20px;
  }

  .application-manage-project-headline_closed {
    color: $white;
    background-color: $brand-danger;
    border-color: $grey-neutral;
    padding: 5px 5px 5px 15px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    margin-top: 20px;
  }

  .application-manage-project-headline_closed a {
    color: $white;
  }

  .application-manage-project-headline a {
    color: $white;
  }

  .bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
  }

  /*** application page new css ***/



  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }

  .single-application {
    background: $white;
    border-radius: 0;
    display: block;
    margin-top: 0;
    padding: 13px;
    position: relative;
    transition: all 0.3s ease 0s;
    width: 100%;
  }

  .single-application-first {
    border-radius: 5px 5px 0 0; margin-top:0px
  }

  .single-application-inner {
    margin-top: 0px;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid $grey-neutral;
    border-top: 8px solid $fl_primary_base;
    box-shadow: $shadow-primary;
  }

  .single-application .hightlight:hover {
    *{
      color: $brand-primary-dark;
    }
  }

  .application-list .single-application:first-child {
    border-radius: 5px 5px 0 0
  }

  .application-list .single-application:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: 1px solid $grey-neutral;
  }

  .single-application-image img {
    border: 1px solid $grey-neutral;
  }

  .single-application-status, .single-application-checkbox, .single-application-image, .single-application-content, .single-application-actions {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
  }

  .single-application-arrow-right {
    font-size: 1.75rem;
    float: right;
    margin-right: 1rem;
    cursor: pointer;

    @include respond-to(small){
      position: absolute;
      bottom: 1rem;
      right: 1.5rem;
      margin: 0;
    }
  }

  .grayed-out .single-application-arrow-right {
    position: relative;
  }

  .no-checkbox .single-application-arrow-right {
    margin-left: 80px;
  }

  .single-application-actions {
    margin-right: 0
  }

  .single-application-status,
  .single-application-checkbox {
    text-align: center;
    width: 20px;
  }

  .single-application-image {
    width: 75px;
  }

  .single-application-inner .booking-actions {
    margin-left: 20px;
    margin-right: 0;
  }

  .single-application-content {
    width: calc(100% - 410px);
  }

  .single-application-inner .single-application-content {
    width: calc(100% - 311px);

  }

  .single-application-inner .booking {
    width: calc(100% - 511px);
  }

  .single-application-content > h3 {
    color: $black;
    font-size: 20px;
    margin-bottom: 7px;
  }

  /*.single-application-content >*/ h3.unread {
    font-weight: 900;
  }
  
  .single-application-content > p,
  a:hover .single-application-content > p,
  a:hover .single-application-checkbox i,
  a:hover .single-application-status i,
  .single-application-checkbox i,
  .single-application-status i {
    margin-bottom: 0;
    color: $grey
  }

  .conversation-left {
    border: 1px solid $grey-neutral;
    border-radius: 5px;
    box-shadow: $shadow-primary;
    margin-bottom: 45px;
    margin-right: 20%;
    clear: left;
  }

  .conversation-right {
    border: 1px solid $grey-neutral;
    border-radius: 5px;
    box-shadow: $shadow-primary;
    margin-bottom: 45px;
    margin-left: 20%;
    margin-right: 15px;
    clear: right;
  }

  .conversation-left .conversation-avatar {
    float: left;
    position: relative;
  }

  .conversation-left .conversation-avatar img.avatar,
  .conversation-left .conversation-avatar a,
  .conversation-left .conversation-avatar p {
    float:left
  }

  .conversation-right .conversation-avatar img.avatar,
  .conversation-right .conversation-avatar a,
  .conversation-right .conversation-avatar p {
    float:right
  }

  .conversation-right .conversation-avatar {
    float: right;
    position: relative
  }

  .conversation-left .conversation-avatar {
    text-align: left
  }

  .conversation-right .conversation-avatar {
    text-align: right
  }

  .conversation-body {
    background: $white none repeat scroll 0 0;
    border-radius: 5px;
    padding: 15px;
  }

  .conversation-avatar img.avatar {
    background: $white none repeat scroll 0 0;
    border: 1px solid $grey-neutral;
    border-radius: 50%;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 14px;
    padding: 3px;
    width: 35px;
    margin-bottom: 15px;
  }

  .conversation-left .conversation-avatar img.avatar {
    margin-left: 7px
  }

  .conversation-right .conversation-avatar img.avatar {
    margin-right: 7px
  }

  .conversation-left .little-arrow-down {
    left: 13px;
    position: absolute;
    top: -1px
  }

  .conversation-right .little-arrow-down {
    right: 13px;
    position: absolute;
    top: -1px
  }

  .conversation-avatar > p {
    color: $grey;
    font-size: 13px;
    line-height: 13px;
    margin-top: 8px;
  }

  .conversation-left .conversation-avatar p {
    margin-top:25px;
    margin-left:6px
  }

  .conversation-right .conversation-avatar p {
    margin-top:25px;
    margin-right:6px
  }

  .conversation-details {
    float: right;
    padding-top: 6px;
  }

  .conversation-details > p {
    color: $grey;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: -15px;
    text-align: right;
  }

  .conversation-attachemnets {
    border-top: 1px solid $grey-neutral;
    margin-top: 15px;
    padding-top: 15px;
  }

  .app-converstation h2 {
    margin-bottom: 5px;
    margin-top: 20px;
  }

  .app-converstation {
    border: 2px solid $grey-neutral-light;
    border-top: none;
    padding: .5rem .5rem 0 .5rem;
    margin-bottom: 1rem;
  }

  .conversation-scroll {
    border-top: 1px solid $grey-neutral;
    height: calc(100vh - 470px);
    overflow-y: scroll;
    padding-top: 15px;
    min-height:250px
  }

  .conversation-reply {
    border-top: 1px solid $grey-neutral;
  }


  /**  CUSTOM CHANGES **/

  .select-action {
    width: 200px !important;
  }

  /*input[type="checkbox"].checkbox-bulk {
    width: 30px;
    height: 30px;
    transform: scale(1);
    top: 6px
  }

  input[type="checkbox"].checkbox-bulk + label:before {
    border: 1px solid $grey-neutral;
    color: grey;
    width: 16px;
    height: 16px;
    font-weight: 100;
    font-size: 0.6em !important;
    margin-top: -5px;
  }*/

  /*input[type="checkbox"].checkbox-application, input[type="checkbox"].checkbox-internal-mail {
    width: 30px;
    height: 30px;
    transform: scale(1);
    top: 30px;
    left: 9px;
  }

  input[type="checkbox"].checkbox-application + label:before,  input[type="checkbox"].checkbox-internal-mail + label:before{
    border: 1px solid $grey-neutral;
    color: $grey;
    width: 16px;
    height: 16px;
    font-weight: 100;
    padding-top: 3px !important;
    padding-left: 1px;
    font-size: 0.6em !important;
  }*/

  .single-application-inner .single-application-content4 {
    width: calc(100% - 300px);
  }

  .single-application-details {
    width: 190px;

    .project-detail-status {
      max-width: 100%;
      width: 100%;
    }

  }

  .new-tabs .nav-pills li {
    margin: 0;
    padding: .25rem .75rem;
    text-transform: uppercase;
    transition: all 0.3s ease;
    background: $white;
    width: 100%;

    &.active,
    &:hover{
      a{
        color: $black;
        border-bottom: 2px solid $brand-primary;
      }
    }

    > a {
      color: $grey;
      padding: .5rem;
      border-radius: 0;
      border-bottom: 1px solid $grey-neutral;
    }

    &:hover > a,
    &:active > a {
      color: $black !important
    }

    @include respond-to(small){
      padding: .25rem;
      margin-right: 6px;
      background: transparent;
      width: auto;

      &.active,
      &:hover{
        background: $white;

        a{
          background: transparent;
          border: none;
        }
      }

      > a {
        border: none;
      }
    }

    @include respond-to(medium) {
      padding: .25rem .75rem;
    }
  }

  .search-users .btn.dropdown-toggle.btn-default,
  .search-users .bootstrap-select,
  .search-online .btn.dropdown-toggle.btn-default,
  .search-online .bootstrap-select{
    height: 50px;
  }

  .single-application-status,
  .single-application-checkbox,
  .single-application-image,
  .single-application-content,
  .single-application-actions,
  .single-application-details {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    position: relative
  }


  /* media queries */

  @media (max-width:1025px) {

    .package-option {
      margin: 0 25px;
      width: 162px;
    }

    .hide1024 { display:none }

  }



  @media (max-width: 960px) {

    .freelancer-content { width:450px }

    .package-option {
      margin: 0 15px;
      width: 162px;
    }

    .certs {
      margin-bottom: 20px;
      text-align: center !important;
    }

    .form-box-submit-area .btn {
      display: block;
      float: none !important;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: 15px;
    }

    .conversation-details {
      display: none;
    }

  }




  @media (max-width: 800px) {

    .freelancer-content { width:300px }

    .freelancer-image { vertical-align: top }

  }


  @media (max-width: 768px) {

    .searchfield  .sorting  { padding:0; margin-bottom:10px}

    .searchfield  .sorting:last-child { margin-bottom:0}

    .package-option {
      margin: 0 0 20px;
      width: 49%;
    }

    .nav-justified > li, .nav-stacked > li,
    .nav-tabs.nav-justified > li {
      background: $black none repeat scroll 0 0;
      border-radius: 4px;
      color: $white;
      float: none;

    }

    .nav-pills > li > a {
      color: $white;
    }

    .show768 { display: inline }

    .single-application-content {
      width: calc(100% - 300px);
    }

    .single-application-inner .single-application-content {
      width: calc(100% - 242px);
    }

    .single-application-actions {
      margin-right: 0 !important;
      width: 90px;
    }

    .single-application-actions span, a:hover .single-application-actions span {
      //margin:5px 5px 5px 0
    }

    .hide768 { display: none}

  }


  @media (max-width: 640px) {

    .freelancer-image,
    .freelancer-content,
    .freelander-details { display: block}

    .freelancer-image {
      float: left;
      vertical-align: top;
    }

    .freelancer-content {
      float: left;
      padding-right: 30px;
      width: calc(100% - 95px);
    }

    .freelancer-content h3 { font-size: 19px }

    .freelander-details {
      clear: both;
      padding-top: 15px;
    }

    .project-detail-status { margin-bottom:0}

    .payment-option {
      margin-bottom: 20px;
    }

    .single-application-checkbox { display:none }

    .single-application-arrow { display:inline-block}

    .single-application-content {
      width: calc(100% - 210px);
    }

    .single-application-status, .single-application-checkbox, .single-application-image, .single-application-content, .single-application-actions {
      margin-right: 10px;
    }

    .single-application-actions a {
      display: inline-block;
      margin-bottom: 7px;}

    .single-application-image { display:none !important;}

    .hide640 { display: none}

  }


  @media (max-width: 480px) {

    .freelancer-image {
      width: 65px;
    }

    .freelancer-content {
      width: calc(100% - 65px);
    }

    .freelander-details {
      clear: both;
      padding-left: 65px;
      padding-top: 7px;
    }

    .package-option {
      width: 100%;
    }

    .single-application-image { display:none}

    .single-application-content {
      width: calc(100% - 180px);
    }

    .single-application-inner .single-application-content {
      width: calc(100% - 150px);
    }

    .single-application-content > h3 {
      color: black;
      font-size: 17px;
      margin-bottom: 4px;
    }

    .app-converstation h2 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 1px;
      margin-top: 20px;
      padding-top: 0;
    }

    .single-application.single-application-inner {
      padding: 10px;
    }

    .app-converstation {
      padding: 10px;
    }

    .conversation-scroll {
      border-top: 1px solid $grey-neutral;
      height: 350px;
      padding-top: 10px;
    }

    .conversation-left {
      margin-right: 30px;
      margin-bottom: 52px;
    }

    .conversation-right {
      margin-bottom: 52px;
      margin-left: 30px;
    }

    .conversation-left .conversation-avatar img.avatar {
      float: left;
      height: 30px;
      margin-top: 15px;
      width: 30px;
    }

    .conversation-right .conversation-avatar img.avatar {
      float: right;
      height: 30px;
      margin-top: 15px;
      width: 30px;
    }

    .conversation-left .conversation-avatar > p {
      float: left;
      margin-left: 7px;
      margin-top: 16px;
      text-align: left;
    }

    .conversation-right .conversation-avatar > p {
      float: left;
      margin-right: 6px !important;
      margin-top: 16px;
      text-align: right;
    }


    .no-checkbox .single-application-arrow-right {
      margin-left: 40px !important;
    }

  }


  @media (max-width: 380px) {

    .freelancer-image { width:100%; padding-right:0; margin-bottom:10px }

    .media-left .media-object {
      max-width: 100%;
      width: 150px;
    }

    .freelancer-content {
      padding: 0;
      width: 100%;
    }

    .freelander-details {
      padding-left: 0;
    }

    .single-application-content > p {
      display: none;
    }

  }

  .single-application-info {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    color: $grey;
    min-width: 20px;
  }

  .checkbox-position-fix {
    top: 0 !important;
    left: 0 !important;
  }

  .loading_typing_notification {
    display: none;
    margin-left: 2px;
    color: $brand-primary;
    font-weight: normal;
  }

  .loading_typing_notification:after {
    content: ' .';
    animation: dots 2s steps(1, end) infinite;
  }

  @keyframes dots {
    0%, 12.5% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    37.5% {
      text-shadow: .5em 0;
    }
    50% {
      text-shadow: .5em 0, 1em 0;
    }
    62.5% {
      text-shadow: .5em 0, 1em 0, 1.5em 0;
    }
    75% {
      text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
    }
    87.5%, 100%{
      text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    }
  }


}

.tags-avatar{
  display: flex;
  flex-wrap: wrap;

  li{
    display: flex;
    align-items: center;
    background: $fl_gray_light-10;
    padding: 0 8px 0 0;
    margin-right: 6px;
    border-radius: 100px;

    .avatar{
      margin: 0;
    }
  }
}



