.watchlist {

  .panel-footer {
    padding: 0 10px 10px 10px !important;
  }

  .panel-heading .name a {
    color: $grey;
  }

  .panel-collapse .panel-body {
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .icons-spacing ul li{
    padding-top: 15px;
    padding-right: 9px;
  }

  .icons-spacing ul li:last-child{
    padding-top: 15px;
    padding-right: 0px;
  }

  .icons-spacing .fa-edit {
    top: -2px;
  }

  .project-name, .company-name, .last-change {
    margin-bottom: 10px;
  }

  .project-name a {
    color: $grey;
  }


  .freelancer-action { width:53px}

   .project-detail-status .far {
     right: 0;
   }

  .freelancer-content {
    max-width: 370px;
  }

  .freelancer-action { width:53px}

  .freelancer-content > h3 {
    margin-bottom: 0;
    padding-top: 0;
  }

  .certificate_class {
    border-radius: 3px;
  }

  .yournotes {
    border-top: 1px dotted $grey-neutral;
    color: $black !important;
    padding-top: 15px;
    text-transform: uppercase;
  }

  .freelancer-descr { margin-bottom: 5px }

  .freelancer-descr > span { margin-bottom: -3px}

}

.blacklist-element .removeThis {
  right: 15px;
  top: 15px;
  z-index: 100;
}


/* blacklist css */


.blacklistRemove {
  border: 1px solid $grey-neutral;
  border-radius: 50%;
  display: block;
  height: 21px;
  padding-top: 3px;
  position: absolute;
  right: 15px;
  text-align: center;
  width: 21px;
  transition: all 0.3s ease
}

.blacklistRemove span { transition: all 0.3s ease }

.blacklistRemove:hover { border-color: $brand-danger;}

.blacklistRemove:hover span { color: $brand-danger}

.blacklist-element {
  border: 1px solid $grey-neutral;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
}

.blacklist-element .media-left img {
  border: 1px solid $grey-neutral;
  height: auto;
  max-width: 100%;
  width: 80px;
}

.blacklist-list .freelancer-content {
  max-width: 500px;
}

.blacklist-list .freelancer-details .project-detail-status {
  max-width: 100%;
}

.blacklist-element .freelancer-image,
.blacklist-element .freelancer-content,
.blacklist-element .freelancer-details{
  display:block;
  float:left
}

.single-profile {
  &__checkbox-box {
    display: table-cell;
    vertical-align: middle;
    width: 30px;
  }
}


.blacklist {
  &-element {
    display: flex;
  }
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 15px 0;
    position: relative;
    min-height: 30px;
    input[type="checkbox"] {
      top: 0 !important;
      left: 0 !important;
    }
  }
  &-checkbox-box {
    display: flex;
    align-items: center;
    margin-right: 15px;
    vertical-align: middle;
    text-align: center;
    width: 20px;
    position:relative;
  }
}

.single-profile {
  &__checkbox-box {
    display: table-cell;
    vertical-align: middle;
    width: 30px;
  }
}


.watchlist {
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 15px 0;
    position: relative;
    min-height: 30px;
    input[type="checkbox"] {
      top: 0 !important;
      left: 0 !important;
    }
  }
  &-checkbox-box {
    display: flex;
    align-items: center;
    margin-right: 15px;
    vertical-align: middle;
    text-align: center;
    width: 20px;
    position: relative;
  }
}
