//
// BOOTSTRAP VARIABLES
// --------------------------------------------------
$bootstrap-sass-asset-helper: false !default;

//Updated color palette
//Blue
$fl_primary_dark-60:  #10484E;
$fl_primary_dark-40:  #176C76;
$fl_primary_dark-20:  #1F909D;
$fl_primary_base:     #27B4C4;
$fl_primary_light-50: #92D9E1;
$fl_primary_light-30: #BDE8ED;
$fl_primary_light-10: #E9F7F9;
$fl_primary_light-5:  #F4FBFC;

//Yellow
$fl_secondary_dark-60:  #664C04;
$fl_secondary_dark-40:  #997207;
$fl_secondary_dark-20:  #CC9809;
$fl_secondary_base:     #FFBE0B;
$fl_secondary_light-50: #FFDE84;
$fl_secondary_light-30: #FFEBB5;
$fl_secondary_light-10: #FFF5DA;
$fl_secondary_light-5:  #FFF9E6;

//Pink
$fl_accent_dark-60:  #570424;
$fl_accent_dark-40:  #840637;
$fl_accent_dark-20:  #AF0849;
$fl_accent_base:     #DB0A5B;
$fl_accent_light-50: #ED84AC;
$fl_accent_light-30: #F4B5CD;
$fl_accent_light-10: #FCE6EE;
$fl_accent_light-5:  #FDF2F6;

//Success
$fl_success_dark-60:  #003300;
$fl_success_dark-40:  #004D00;
$fl_success_dark-20:  #006700;
$fl_success_base:     #008000;
$fl_success_light-50: #7FBF7F;
$fl_success_light-30: #B2D9B2;
$fl_success_light-10: #E5F2E5;
$fl_success_light-5:  #F2F9F2;

//Danger
$fl_danger_dark-60:  #550000;
$fl_danger_dark-40:  #800000;
$fl_danger_dark-20:  #AB0000;
$fl_danger_base:     #D50000;
$fl_danger_light-50: #EA7F7F;
$fl_danger_light-30: #F2B2B2;
$fl_danger_light-10: #FBE5E5;
$fl_danger_light-5:  #FDF2F2;

//Grays
$fl_gray_dark-60:  #242D2E;
$fl_gray_dark-40:  #364545;
$fl_gray_dark-20:  #485B5C;
$fl_gray_base:     #5A7273;
$fl_gray_light-50: #ACB8B8;
$fl_gray_light-30: #CDD4D5;
$fl_gray_light-10: #EEF1F1;
$fl_gray_light-5:  #F6F8F8;


// Colors
$gray-base:     #000000 !default;
$gray-dark:     #808080 !default;
$gray:          #a9a9a9 !default;
$gray-light:    #dcdcdc !default;

$black:         $fl_gray_dark-60;
$white:         #ffffff;

$grey:          $fl_gray_base; //slate
$grey-light:    $fl_gray_light-50;
$grey-lighter:  $fl_gray_light-30;

$grey-neutral: $fl_gray_light-10;
$grey-neutral-light: $fl_gray_light-5;

$brand-primary: $fl_primary_base; // turquoise
$brand-primary-dark: $fl_primary_dark-40;
$brand-primary-light: $fl_primary_light-30;
$brand-primary-lighter: $fl_primary_light-5;

$brand-secondary: $fl_accent_base; // pink
$brand-secondary-dark: $fl_accent_dark-40;
$brand-secondary-light: $fl_accent_light-30;
$brand-secondary-lighter: $fl_accent_light-5;

$brand-success: $fl_success_base; // green
$brand-success-dark: $fl_success_dark-40;
$brand-success-light: $fl_success_light-30;
$brand-success-lighter: $fl_success_light-5;

$brand-info: $fl_secondary_base; // yellow
$brand-info-dark: $fl_secondary_dark-40;
$brand-info-light: $fl_secondary_light-30;
$brand-info-lighter: $fl_secondary_light-5;

$brand-warning: $fl_primary_base;
$brand-warning-dark: $fl_primary_dark-40;
$brand-warning-light: $fl_primary_light-30;
$brand-warning-lighter: $fl_primary_light-5;

$brand-danger: $fl_danger_base; // red
$brand-danger-dark: $fl_danger_dark-40;
$brand-danger-light: $fl_danger_light-30;
$brand-danger-lighter: $fl_danger_light-5;




$green: $fl_success_base;
$green-dark: $fl_success_dark-40;
$green-light: $fl_success_light-30;


$colors: (
        'white': $white,
        'gray': $grey,
        'green': $fl_success_base,
        'green-light': $fl_success_light-30,
        'red': $fl_danger_base,
        'black': $black,
) !default;


//Sizes
$rem: 16px;

// Scaffolding
$body-bg:               $white;
$text-color:            $black;
$link-color:            $fl_primary_base;
$link-hover-color:      $fl_primary_dark-40;
$link-hover-decoration: none;

// Typography
$font-primary: 'Source Sans Pro', sans-serif;
$font-icon: 'Font Awesome 5 Pro';
$font-family-sans-serif:  $font-primary!default;
$font-family-base:        $font-primary!default;
$font-size-base:          $rem !default;
$font-size-large:         20px !default;
$font-size-small:         11px !default;
$font-size-h1:            3rem !default;
$font-size-h2:            2.25rem !default;
$font-size-h3:            2rem !default;
$font-size-h4:            1.5rem !default;
$font-size-h5:            1.125rem !default;
$font-size-h6:            1.125rem !default;
$line-height-base:        1.3 !default;
$line-height-computed:    10px !default;
$headings-line-height:    1 !default;

// Components
$border-radius-base:  6px !default;
$border-radius-large: 8px !default;
$border-radius-small: 4px !default;

// Buttons
$btn-font-weight:    700 !default;
$btn-default-color:  $white!default;
$btn-default-bg:     $black !default;
$btn-default-border: transparent !default;

// Forms
$input-bg:                 $white !default;
$input-bg-disabled:        $grey-neutral-light !default;
$input-color:              $black !default;
$input-border:             $fl_gray_light-30;
$input-border-focus:       $fl_primary_light-50 !default;
$input-color-placeholder:  $grey !default;
$input-height-base:        36px !default; // default button
$input-height-large:       46px !default; // large button
$input-height-small:       39px !default; // small button
$form-group-margin-bottom: 1rem !default;
$input-border-radius:      6px;
$border-radius-large:      0;


$input-inline-width: 100px !default;
$input-control-color: $black !default;


// Dropdowns
$dropdown-bg:                  $white !default;
$dropdown-border:              transparent !default;
$dropdown-fallback-border:     transparent !default;
$dropdown-link-color:          $white !default;
$dropdown-link-hover-color:    $white !default;
$dropdown-link-hover-bg:       $brand-primary !default;
$dropdown-link-active-color:   $white !default;
$dropdown-link-active-bg:      $brand-primary !default;
$dropdown-link-disabled-color: $grey !default;

// Media queries
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

// Grid system
$grid-gutter-width: 24px !default;

// Navs
$nav-link-padding:  7px 0 !default;
$nav-link-hover-bg: $brand-primary !default;

// Tabs
$nav-tabs-border-color:                       $grey-neutral !default;

// Pills
$nav-pills-border-radius:           $border-radius-base !default;
$nav-pills-active-link-hover-bg:    $brand-primary !default;
$nav-pills-active-link-hover-color: $white !default;

// Pagination
$pagination-color:         $grey !default;
$pagination-bg:            transparent !default;
$pagination-border:        transparent !default;
$pagination-hover-color:   $fl_primary_dark-40 !default;
$pagination-hover-bg:      transparent !default;
$pagination-hover-border:  transparent !default;
$pagination-active-color:  $fl_primary_dark-40 !default;
$pagination-active-bg:     transparent !default;
$pagination-active-border: transparent !default;

// Alerts
$alert-padding:       .5rem 1rem !default;
$alert-border-radius: 1.25rem !default;
$alert-success-bg:    $fl_success_light-50;
$alert-success-text:  $black !default;
$alert-info-bg:       $fl_secondary_light-50;
$alert-info-text:     $black !default;
$alert-warning-bg:    $fl_primary_light-50;
$alert-warning-text:  $black !default;
$alert-danger-bg:     $fl_danger_light-50;
$alert-danger-text:   $black !default;

// Progress bars
$progress-bg:        $grey !default;
$progress-bar-color: $white !default;
$progress-bar-bg:    $brand-primary !default;
$progress-border-radius:  $border-radius-base;


// List group
$list-group-bg:                 $grey-neutral-light !default;
$list-group-border:             transparent !default;
$list-group-border-radius:      $border-radius-base !default;
$list-group-hover-bg:           $brand-primary !default;
$list-group-active-color:       $white !default;
$list-group-active-bg:          $brand-primary !default;
$list-group-active-border:      transparent !default;
$list-group-active-text-color:  $white !default;
$list-group-link-color:         $grey !default;
$list-group-link-hover-color:   $white !default;
$list-group-link-heading-color: $white !default;

// Panels
$panel-bg:                 $white !default;
$panel-body-padding:       15px !default;
$panel-heading-padding:    5px 5px 5px 15px !default;
$panel-footer-padding:     0 15px 10px 0 !default;
$panel-border-radius:      $border-radius-base !default;
$panel-inner-border:       $white !default;
$panel-footer-bg:          $white !default;
$panel-default-text:       $white !default;
$panel-default-border:     $grey-neutral !default;
$panel-default-heading-bg: $grey !default;

//
// CUSTOM VARIABLES
// --------------------------------------------------

// WIDTH
$gap-width-small: 12px !default;
$gap-width-large: 20px !default;
$max-width: 1200px !default;
$menu-width: 160px !default;
$menu-width-small: 36px !default;
$content-secondary-width: 200px !default;

// BREAKPOINTS
$breakpoints: (
  'xsmall': (min-width: $screen-xs),
  'small': (min-width: $screen-sm),
  'medium': (min-width: $screen-md),
  'large': (min-width: $screen-lg)
) !default;


// PATH
$image-path: '../img' !default;
$font-path: '../fonts' !default;