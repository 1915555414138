/* Single testimonial */
.single-testimonial {
  position: relative;
  display: block;
  page-break-inside: avoid;
  padding: .5rem;

  .single-testimonial-content{
    background-color: $white;
    padding: .5rem 0;

    q {
      font-style: italic;
      font-size: .875rem;
      text-align: left;
      word-break: break-word;
      position: relative;
      z-index: 1;

      &::before{
        content:"\f10e";
        font-family: $font-icon;
        font-size: 2rem;
        font-weight: 700;
        color: $brand-primary-light;
        display: block;
        position: absolute;
        top: -1rem;
        left: -1rem;
        z-index:-1;
      }
    }
  }

  .testimonial-author {
    font-size: .875rem;

    div {
      text-align: center;

      a span{
        display: block;
        color: $black;
        font-weight: 700;
      }

      small,
      time {
        color: $grey;
        font-size: .75rem;
        display: block;
      }
    }

    img{
      border: 1px solid $grey-neutral;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: $shadow-primary;
      margin: 0 auto .5rem auto;
    }
  }
}

/* Carousel styling */
.testimonial-carousel{
  .owl-item {
    @include respond-to(small){
      .single-testimonial {
        .single-testimonial-content {
          q {
            display: none;
          }
        }

        .testimonial-author {
          div {
            time {
              display: none;
            }
          }
        }
      }
    }

    &.active {
      &.center {
        .single-testimonial .single-testimonial-content {
          padding: 2rem 1.75rem .75rem 1.75rem;
          text-align: left;

          .testimonial-author {
            float: right;
            margin-top: 2rem;
            text-align: right;

            .author-image{
              float: right;
            }

            div {
              display: inline-block;
              text-align: right;
              margin: 1rem 1rem 0 0;
            }
          }

          q,
          time {
            display: inline-block;
          }

          .testimonial-placeholder {
            display: none;
          }
        }

        .item {
          width: 100%;
        }
      }
    }
  }

  .owl-nav{
    .owl-prev,
    .owl-next {
      position: absolute;
      width: 2rem;

      i {
        color: $grey;
        font-size: 3rem;
      }

      &:hover{
        background: transparent;

        i{
          color: $brand-primary-dark;
        }
      }
    }

    .owl-prev{
      left: 0;
    }

    .owl-next{
      right: 0;
    }

    @include respond-to(small){
      position: absolute;
      top: 4rem;
      width: 100%;

      .owl-prev,
      .owl-next {
        i {
          font-size: 5rem;
        }
      }
    }
  }

  @include respond-to(small) {
    .owl-stage-outer{
      &::before,
      &::after {
        content:'';
        width: 8rem;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        z-index: 1;
      }

      &::before {
        background-image: linear-gradient(to right, $grey-neutral-light 50%, rgba($grey-neutral-light, 0));
      }

      &::after {
        background-image: linear-gradient(to left, $grey-neutral-light 50%, rgba($grey-neutral-light, 0));
        right:0;
      }
    }

    .owl-stage {
      max-height: 350px !important;
      display: flex !important;
      align-items: center !important;
    }

    .owl-item {
      .item {
        width: 170px !important;
        padding: 0!important;
      }

      &.active {
        &.center {
          .item {
            width: 350px !important;
            -webkit-transition: width 0ms;
            -moz-transition: width 0ms;
            -ms-transition: width 0ms;
            -o-transition: width 0ms;
            transition: width 0ms;
          }
        }
      }
    }
  }

  @include respond-to(large){
    .owl-item {
      &.active {
        &.center {
          .item {
            width: 480px !important;
          }
        }
      }
    }
  }

}

.fix-testimonials-link{
  padding-top: 4rem;

  @include respond-to(small){
    padding-top:0;
  }
}

/* Carousel position fix */
@include respond-to(small){
  .fix-pos-first{
    left:0;
  }

  .fix-pos-last{
    left: -90px;
  }

  .fix-pos{
    left:90px;
  }
}

@include respond-to(large){
  .owl-stage{
    left:10px;

    &.fix-pos-first{
      left: 10px;
    }

    &.fix-pos-last{
      left: -145px;
    }

    &.fix-pos{
      left: 165px;
    }
  }
}

