#profile_view {
  .media {
    overflow: visible;

    .media-left {
      position: relative;
    }
  }

  .profile-status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 37px;
    width: 37px;
    font-size: 20px;
    background: $white;
    border-radius: 50%;

    &.certificate-icon {
      top: -8px !important;
      left: -8px;
      border: 2px solid $brand-primary;
      color: $brand-primary;
    }

    &.data-policy-icon {
      bottom: -8px !important;
      right: -8px;
      border: 2px solid $grey;
    }
  }
}

.availability-alert-off{
  &.badge:empty{
    display: inline-block;
  }

  &::before{
    font-family: 'Font Awesome 5 Pro';
    content: '\f8fa';
    font-weight: 400;
    color: $white;
    margin-right: 4px;
  }

  &::after{
    content: "NEU";
  }
}

.availability-alert-on{
  &::before{
    font-family: 'Font Awesome 5 Pro';
    content: '\f8fa';
    font-weight: 900;
    color: $primary-dark;
  }
}
