.alert{
  border: none;
  font-size:.875rem;

  p{
    font-weight:400;
    font-size: .875rem;
  }

  a:not(.btn){
    font-weight: 600;

    &:hover{
      text-decoration: underline;
    }
  }
}


@mixin alert-variants($color-dark) {
  i,
  a:not(.btn){
    color: $color-dark;
  }
}

.alert-info {
  @include alert-variants($fl_secondary_dark-40);
}

.alert-warning {
  @include alert-variants($fl_primary_dark-40);
}

.alert-danger {
  @include alert-variants($fl_danger_dark-40);
}

.alert-success {
  @include alert-variants($fl_success_dark-40);
}


.alert-dismissible {
  padding-right: 2.25rem;

  .close {
    font-size: 1.25rem;
  }

  .close:focus,
  .close:hover {
    color: #fff;
    filter: alpha(opacity=100);
    opacity: 1;
  }
}
