.example {
  display: flex;
  transition: all .5s;
  user-select: none;
  background: linear-gradient(to bottom, white, black);
}

.modal input.form-control {
  &::placeholder{
    font-style: italic;
  }
}

#report_duplicate_project p{
  font-size: .875em;
}

// hide arrows in FAQ on mobile
@media only screen and (max-width: 460px) {
}

// price table in FAQ
.faq-table{
  th,
  &> tbody > tr > td {
    padding: .5rem;
    border: 1px solid $grey-neutral;
    overflow: hidden;
    word-break: normal;
    text-align: center;
    vertical-align: top;
  }
}

.thumbnail-image-zoomed {
  position: absolute;
  left: 60px;
  top: 10px;
  background: $white;
  border: 1px solid $grey-neutral;
  pointer-events: none;
  z-index: 1;
  box-shadow: $shadow-primary;
}

.progress-bar2 {
  background: $grey-neutral-light;
  clear: both;
  display: block;
  margin: .5rem auto;
  width: 100%;
}

.progress-bar-inner2 {
  background: $brand-primary;
  color: $white;
  display: block;
  font-size: .75rem;
  font-weight: 600;
  padding:.25rem;
}

.availability {
  font-weight: 600;
  cursor: pointer;
  color: $grey;

  &.availability-yes{
    color: $brand-success;

    &:hover{
      color: $brand-success-dark;
    }
  }

  &.availability-no{
    color: $brand-danger;

    &:hover{
      color: $brand-danger-dark;
    }
  }
}

.dashboard{
  .avatar{
    margin: 0;
    padding: 0;

    &.avatar-upgrade{
      max-width: 50px;
    }
  }

  .item-mail {
    cursor: pointer;
  }

  .item-mail-unread *{
    color: $black;
    font-weight: 900!important;
  }

  .overlay-div {
    position: relative;
  }

  .overlay-image {
    position: absolute;
    top: -100px;
    left: 0px;
    z-index: 100;
    border: 1px solid $grey-neutral;
  }

  .media, .media-body {
    overflow: visible;
  }

  .media:not(:last-child){
    padding-bottom: 1rem;
    border-bottom: 1px dashed $grey-neutral;
    margin-bottom: 1rem;
  }
}

.additional-box{
  margin: 0 .5rem;
  padding: .5rem .25rem;

  &:last-of-type{
    margin-bottom: 1rem;
  }
}


// Switch
.bootstrap-switch {
    border-radius: 99px;
    border: 2px solid;
    line-height: 8px;
    background: $white;

  &.bootstrap-switch-on,
  &.bootstrap-switch-on.bootstrap-switch-focused {
    border-color: $brand-primary;
    box-shadow: none;
  }

  &.bootstrap-switch-off,
  &.bootstrap-switch-off.bootstrap-switch-focused {
    border-color: $grey-neutral;
    box-shadow: none;
  }

  .bootstrap-switch-container{
    .bootstrap-switch-handle-on {
      border-radius: 99px;
      font-weight: 600;
    }

    .bootstrap-switch-handle-off {
      border-radius: 99px;
      color: $black;
      background: $grey-neutral;
      font-weight: 600;
    }
  }
}

//Search - compact
.searchpanel.sp_compact {
  position: fixed;
  z-index: 1000;
  top: 0;
  padding: 0;
  margin: 0;
  background: none;
}

.project-reference-number{
  float: left;
  min-width: 10%;
}

.panel-bar-online {
  background: $brand-success-lighter;
}

.panel-bar-offline {
  background: $brand-danger-lighter;
}

.input-tips {
  position: absolute;
  width: 360px;
  height: auto;
  background: $white;
  border: 1px solid $grey-neutral;
  right: -368px;
  top: 1rem;
  padding: .5rem;
  display: none;
  p {
    font-weight: 400;
  }

  ul li{
    margin-bottom:0;
  }

  @media (max-width: 1399px) {
      display: none !important;
  }
}

.action{
  margin-bottom:4px;

  i{
    margin-right:4px;
  }
}

//Contact data fix in project detail
#contact_data .panel{
  box-shadow: none;
}

//Cookie consent
.cc-window {
  box-shadow: $shadow-primary;
  border-radius: 0!important;
  font-size: .875rem;
  display: inline;

  .cc-btn {
    margin-top: 1rem;
    background: $black!important;
    border-radius: 0;
    display: block!important;
    max-width:180px;
  }
}

.booking-beta {
  color: $white;
  font-size: 14px;
  background: $brand-primary;
  border: none;
  border-radius: 2px;
  text-shadow: none;
  padding: 3px 2px;
}


// LATEST NEWS (moved from Graphic studio:nav
.latest-news ul li {
  border-bottom: 1px solid $grey-neutral;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.latest-news ul li:last-child {
  border-bottom: medium none;
  margin-bottom: 0;
  padding-bottom: 4px;
}
.latest-news ul li img {
  float: left;
  margin-right: 15px;
}
.latest-news ul li a {
}
.latest-news ul li a h4 {
  color: $fl_primary_dark-40;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: -4px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.latest-news ul li a:hover h4 {
  color: $black;
}

.latest-news ul li small {
  display: block;
  font-size: 13px;
  line-height: 14px;
}
.latest-news .entry-meta {
  color: gray;
  font-size: 12px;
  margin-top: 5px;
}