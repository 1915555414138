@media (min-width: $screen-sm) {

  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    -webkit-flex-flow: row;
  }

  .col-stretch{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    -webkit-flex-flow: column;
    justify-content: space-between;
  }

}

@include respond-to(medium){
  .row-eq-height-md {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    -webkit-flex-flow: row;
  }
}

.hr-primary{
  border-top: 5px solid $black;
  margin: 2.5rem auto;
}

.hr-secondary{
  border-top: 2px solid $grey;
  margin: 2.5rem auto;
}

.main{
  background-color: $grey-neutral-light;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: $shadow-primary;
}

.wrapper-lp{
  margin:0 auto;
}

.bg-hero {
  background-color: $fl_primary_light-10;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem 0;
  margin-left: -12px;
  margin-right: -12px;
}

.fl-hero-box {
  background: $fl_primary_base;
  padding: 1.5rem;
  border-top-right-radius: 24px;

  h1{
    font-size: 34px;
  }
}

@include respond-to(xsmall){
  .fl-hero-box {
    margin-top: 180px;
  }
}

@include respond-to(medium){
  .bg-hero {
    padding:3rem;
  }

  .fl-hero-box {
    background: $fl_primary_base;
    padding: 1.5rem;
  }
}