// Colors
$gray-base:     $black !default;
$gray-dark:     $grey !default;
$gray:          $grey-light !default;
$gray-light:    $grey-lighter !default;
$green-regular: $brand-success;
$yellow: $brand-info;
$green: $brand-success !default;
$green-dark: $brand-success-dark !default;
$green-light: $brand-success-light !default;
$primary-dark:  $brand-primary-dark !default;

$shadow-primary: 0 2px 6px rgba($grey, .35);
$shadow-no-top: 0 4px 3px rgba($grey, .35);

$shadow-inset: inset 3px 0 5px rgba(0, 0, 0, 0.125);
$shadow-field: inset 1px 1px 1px $fl_gray_light-5;
$shadow-field-focus: 0 1px 3px $fl_gray_light-30;

$transition-normal: .3s;


$colours: (
        //NEW
        'primary-light': $fl_primary_light-30,
        'primary-lighter': $fl_primary_light-10,
        'dark-dark': $fl_primary_dark-60,
        'accent-dark': $fl_accent_dark-40,

        //OLD
        'white': $white,
        'gray': $grey,
        'green': $brand-success,
        'green-light': $brand-success-light,
        'red': $brand-danger,
        'black': $black,
        'gray-dark': $grey,
        'gray-light': $grey-neutral-light,
        'green-regular': $brand-success,
        'primary': $brand-primary,
        'primary-dark': $brand-primary-dark,
        'warning': $brand-warning,
        'yellow': $brand-info,
        'accent': $fl_accent_base
) !default;

$gap-none: 0px !default;
$gap-extra-small: 4px !default;
$gap-small: 8px !default;
$gap-medium: 12px !default;
$gap-large: 16px !default;
$gap-extra-large: 20px !default;

$gaps: (
        'none': $gap-none,
        'no': $gap-none,
        'xs': $gap-extra-small,
        'sm': $gap-small,
        'md': $gap-medium,
        'lg': $gap-large,
        'xl': $gap-extra-large,
) !default;

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  src: url('/css/public-gulp/webfonts/google/SourceSansPro-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  src: url('/css/public-gulp/webfonts/google/SourceSansPro-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  src: url('/css/public-gulp/webfonts/google/SourceSansPro-Bold.woff2')  format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 900;
  src: url('/css/public-gulp/webfonts/google/SourceSansPro-Black.woff2') format('woff2');
}