/* New partial with all styles related to the navigation - both top and west
 * Rules moved over from GraphicStudio _n a v.scss and Freelance m e nu-navigation.scss
 */
.navbar-default{
  background-color: $white;
  border: none;

  .navbar-collapse{
    border:none;
    padding: 16px;
  }

  .navbar-toggle {
    font-size: 20px;
    margin:0;
    padding: 0;
    border:none;

    &:hover,
    &:focus{
      background: none;
    }

    i{
      padding: 4px 10px;
    }
  }

  .avatar {
    margin: 0 4px 0 0;
  }

  .navbar-nav{
    margin: 0;

    > li > a {
      font-weight: 600;
      color: $fl_gray_base;
      line-height: 1;

      &:hover{
        color: $fl_primary_base;
      }
    }

    .open,
    .active {
      .dropdown-menu {
        @media screen and (min-width: 768px){
          &.menu-min-width{
            min-width: 300px;
          }
        }

        > li {
          margin-bottom: 0;
        }

        li > a {
          padding: .5rem 1.5rem;
          color: $fl_gray_base;
          font-weight: 600;
          line-height: revert;
          white-space: normal;

          &:hover{
            color: $fl_primary_base;
          }
        }
      }

      > a, > a:hover, > a:focus{
        color: $black;
        background: none;
      }

      @media screen and (max-width: 767px){
        .fa-angle-down{
          transform: rotate(180deg);
        }
      }
    }

    .btn-nav{
      border: 2px solid $fl_secondary_base;
      border-radius: 6px;
      background: $fl_secondary_base;
      font-weight: 700;
      padding: 6px 12px;

      &:hover{
        border-color: $fl_gray_dark-60;
        color: $fl_gray_dark-60;
        background: $fl_secondary_light-50;
      }
    }
  }

  @include respond-to(small){
    .navbar-collapse{
      padding: 0;
      float: right;

      &.hide-sm-up{
        display: none!important;
      }
    }

    .navbar-nav{
      > li > a {
        font-weight: 400;
        color: $black;
        padding: 8px 14px;

        &.bold{
          font-weight: 700;
        }
      }

      .open {
         .dropdown-menu {
          border: 1px solid $fl_gray_light-10;
          border-radius: 6px;
          padding-top: 8px;
          padding-bottom: 8px;
          margin-top: 6px;
          right: initial;

          li > a {
            color: $black;
            font-weight: 400;

            &:hover{
              color: $fl_primary_base;
              background: transparent;
            }
          }
        }

        > a, > a:hover, > a:focus  {
          color: $fl_primary_base!important;
        }
      }
    }
  }
}

// MOBILE NAVIGATION - west navi
.nav-mobile {
  background-color: $fl_gray_light-5;
  margin: 10px -16px;

  .list-group {
    margin-bottom: calc($line-height-computed/2);
    cursor: pointer;

    a {
      display: none;

      &:first-child {
        display: block;
      }
    }

    &.active {
      a {
        display: block;
      }

      i.fa-angle-down{
        transform: rotate(180deg);
      }
    }
  }

  .list-group-item {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background: transparent;
    border: none;
    color: $fl_gray_base;
  }

  a.list-group-item,
  button.list-group-item {
    font-weight: 600;
    padding-left: 1.5rem ;

    &:first-child {
      padding-left: 1rem ;
    }

    .list-group-item-heading {
      color: $white;
    }

    &:hover,
    &:focus {
      color: $fl_primary_dark-60;
      background-color: transparent;
    }

    @include respond-to(small) {
      padding-left: 1rem;
    }
  }
}

// SIDEBAR NAVIGATION
.panel-welcome{
  margin-bottom: 1rem;
  width: $menu-width-small;
  position: relative;

  .panel-welcome-content{
    display: none;
  }

  @include respond-to(large) {
    width: 100%;

    .panel-welcome-heading{
      display: block;
      padding: .5rem;
      background-image: linear-gradient(to right, $fl_primary_light-10, $fl_primary_light-5);
    }

    .panel-welcome-content{
      display: inline-block;
    }

    .media{
      padding:.5rem;
    }
  }

  img{
    vertical-align: bottom;
    border: 1px solid $grey-neutral;
    border-radius: 100%;
    width: $menu-width-small;
  }

  p{
    font-size: .875rem;
    margin-bottom: 0;
  }

  .minimized & {
    width: 36px;
    border: none;
    padding: 0;

    .panel-welcome-heading{
      padding: 0;
    }

    .panel-welcome-content{
      display: none;
      z-index: 10000;
      background: $white;
      box-shadow: $shadow-primary;
      margin-left: 36px;
      padding: 1rem;
      position: absolute;
      top: 0;
      width: 200px;
      color: $black;
      margin-top:0!important;
    }

    &:hover .panel-welcome-content {
      display: block;
    }
  }
}

.minimized{
  .panel-welcome .media{
    padding: 0;
  }

  .hidden-minimized{
    display: none!important;
  }
}

.nav-aside {
  width: $menu-width;
  display: none;

  @include respond-to(large) {
    display: block;
  }

  .minimized & {
    display: none;
  }

  .list-group {
    margin-bottom: $line-height-computed;
  }

  .list-group-item {
    padding: .5rem 0;
    border: none;
    background: $white;
    line-height: 1;
  }

  a.list-group-item,
  button.list-group-item {

    &:hover,
    &:focus,
    &.active {
      color: $fl_primary_dark-60;
      background: $white;
    }

    &:first-child {
      font-size: .875rem;
      font-weight: 600;
      padding-bottom: 2px;
      color: $black!important;
    }
  }
}

// MINIMIZED SIDEBAR
.nav-aside-small {
  width: $menu-width-small;
  display: none;

  @include respond-to(small) {
    display: block;
  }

  @include respond-to(large) {
    display: none;
  }

  .minimized & {
    display: block;
  }
}

// Minimized nav icon link
.nav-aside-small-link {
  display: block;
  width: $menu-width-small;
  font-size: 1rem;
  text-align: center;
  color: $grey;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;

  .far{
    font-size: 1rem;
  }

  .badge {
    position: absolute;
    right: -5px;
    top: 0;
    font-size: 9px;

    &.read {
      background-color: $gray-dark;
    }

    &.unread {
      background-color: $brand-warning;
    }
  }
}

.nav-aside-small-sub {
  position: absolute;
  top: -10px;
  left: $menu-width-small + $gap-width-small;
  z-index: 1000;
  min-width: 170px;
  display: none;
  background-color: $white;
  padding-bottom:.5rem;
  box-shadow: $shadow-primary;

  li{
    margin:0;
  }

  a {
    @extend %clearfix;
    color: $black;
    text-align: left;
    padding: 0 1rem;
    display: block;
    line-height: $menu-width-small;
    font-size: $font-size-base;
    white-space: nowrap;
    position: relative;

    &:hover{
      color: $brand-primary;
    }

    .far {
      line-height: $menu-width-small;
    }
  }

  .nav-aside-small-sub-main {
    position: relative;
    left: -48px;

    a {
      background-color: $white;
      color: $black!important;
      font-size: .785rem;
      font-weight: 600;
      cursor: default;
      padding-left: 10px;
      border-left: 5px solid $fl_primary_light-30;
    }

    .far {
      color: $fl_primary_base;
      font-size: 1rem;
      margin-right: 28px;
      cursor: pointer;
    }
  }

   .badge {
     position: static;
     margin-top: 10px;
     font-size: 9px;
     float: right;

     &.read {
       background-color: $grey;
     }

     &.unread {
       background-color: $brand-secondary;
     }
   }
 }

 .nav-aside-toggle {
   font-size: 1.25rem;
   padding: .25rem .75rem .25rem .25rem ;
   color: $grey;
   display: none;

   @include respond-to(large) {
     display: inline-block;
   }

   &:hover {
     color: $fl_primary_dark-60;
   }
 }

.open>.dropdown-menu {
  display: block !important;
}
