html,
body{
  overflow-x: hidden;
}

.home {
  .bg-hero-hp{
    background: $brand-primary;
    padding: 5rem 0;

    h1{
      font-size: 1.875rem;
    }

    .input-group{
      max-width:650px;
      margin: 0 auto;
    }

    .form-control{
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding-left: 1rem;
    }

    .input-group-btn .btn{
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-left: 8px;
      padding-right: 10px;
      height: $input-height-base;
    }

    @include respond-to(medium){
      background: url("/images/page_elements/home/freelancer_992-min.jpg") center left no-repeat, $brand-primary url("/images/page_elements/home/projektanbieter_992-min.jpg") center right no-repeat;

      h1{
        font-size: 2.25rem;
      }

      .input-group-btn .btn{
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
    }

    @media screen and (min-width: 1600px) {
      background: url("/images/page_elements/home/freelancer_1600-min.jpg") center left no-repeat, $brand-primary url("/images/page_elements/home/projektanbieter_1600-min.jpg") center right no-repeat;
      padding: 6rem 0;
    }
  }

  .wrapper{
    padding-bottom: 0;
  }

  section{
    margin-top: 1rem;

    //layout shift fix
    & .layout-shift{
      min-height: 280px;
    }

    .img-responsive{
      margin: 2rem auto;

      @include respond-to(small){
        margin: 0 auto;
      }
    }

    .homepage-seo {
      margin: 2.5rem -12px 0 -12px;
    }

    h2{
      font-size: 1.75rem;
    }

    .logos-carousel,
    .logos-static {
      .owl-stage-outer::after{
        content: "";
        display: block;
        background: linear-gradient(to left, #fff 10%, #ffffff00);
        width: 100px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -1rem;
      }

      .logo-holder {
        padding: 10px;
        width: calc(100% - 1px);
        background: $white;

        img {
          margin-left: auto;
          margin-right: auto;
          max-height: 80px;
          max-width: 100%;
          text-align: center;
          width: auto;
        }
      }
    }

    .logos-static{
      .logos-flex{
        @include respond-to(small){
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;

          div{
            margin: 0;
          }
        }

        .img-responsive{
          max-height: 80px;
          width: auto;
        }
      }
    }

    @include respond-to(small){
      padding-bottom: 4rem!important;

      .logos-carousel {
        .owl-stage-outer::after{
          width: 200px;
        }
      }
    }
  }

  .btn-row{
    margin: 1rem 0;

    .btn{
      margin: .5rem .5rem .5rem 0;
    }
  }

  .form-control:focus{
    box-shadow: none;
  }

  .input-group-lg > .form-control,
  .input-group-lg > .input-group-btn > .btn{
    font-size: 1.125rem;
  }
}

/* Extended elements */
.bg-extend-left,
.bg-extend-right{
  position: relative;

  .panel{
    margin-bottom: 1.25rem!important;
  }

  .bg-extend-inner {
    margin: -1rem 0 -4rem;
  }

  @include respond-to(small){
    margin: 0;
  }
}

.bg-extend-left {
  background-image: linear-gradient(to left, #27B4C400 0%, #27B4C4 30%);
  margin-top: 1rem;
  margin-bottom: 6rem;
}

.bg-extend-right {
  background-image: linear-gradient(to right, #27B4C400 0%, #27B4C4 30%);
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.bg-extend-cta{
  position: relative;
  top: 3.5rem;
}

@include respond-to(medium){
  .hr-extend-left::before,
  .hr-extend-right::after{
    content:"";
    height: 5px;
    background: $black;
    display: block;
    position: absolute;
    top: 0;
    width: 99em;
  }

  .hr-extend-left::before{
    left: -99em;
  }

  .hr-extend-right::after{
    right: -99em;
  }

  .bg-extend-left {
    padding-right: 2rem;

    &::before {
      left: -99em;
      background: $brand-primary;
      content: '';
      display: block;
      position: absolute;
      width: 99em;
      top: 0;
      bottom: 0;
    }
  }

  .bg-extend-right {
    padding-left: 2rem;

    &::after {
      right: -99em;
      background: $brand-primary;
      content: '';
      display: block;
      position: absolute;
      width: 99em;
      top: 0;
      bottom: 0;
    }
  }
}

.bg-primary-grad{
  background-image: linear-gradient(to right, $brand-primary 30%, rgba($brand-primary, 0));
}

.footer-banner{
  margin: 0 -12px -37px -12px;
  background: $brand-primary;

  &-link{
    display: inline-block;
    color: $black!important;
    font-weight: 700;
    font-size: 2rem;
    margin-top: 2rem;

    &:hover{
      text-decoration: underline;
    }
  }

  &-text{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    max-width: 330px;
  }

  @include respond-to(small){
    background: none;
    background-image: linear-gradient(to right, $brand-primary 30%, rgba($brand-primary, 0));

    img{
      margin: 0 auto;
    }
  }
}

/* Key value pairs  */
.value-pair {
  margin-bottom: 1rem;

  b{
    font-size: 1.75rem;
  }

  @include respond-to(xsmall){
    display: inline-block;
    width:37%;
  }

  @include respond-to(small){
    width:25%;
  }

  @include respond-to(medium){
    width:17%;
    margin-bottom: 0;
  }
}

.ul-blog{
  li{
    &:first-of-type{
      border-bottom: 1px dashed #5A7273;
      padding-bottom: 1.75rem;
      margin-bottom: 1.75rem;
    }

  }
}

/* move to layout.scss */
.align-items-center {
  align-items: center;
}