#pagination{
  p {
    font-size: .875rem;
    color: $grey;
    font-weight: 400;
  }
}

.pagination {
  margin: 0 0 $gap-width-large;

  @include respond-to(small) {
    margin: 0;
  }

  li a  {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    margin-left: 3px;
    margin-right: 3px;
    padding: 0;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 30px;
  }

  li.active > a, li:hover > a,
  li:focus > a,
  li >  a:focus {
    font-weight: 600;
  }

}

.nav-pagination {
  position: relative;

  @include respond-to(small) {
    text-align: center;
  }
}

.pagination-jump {
  position: absolute;
  right: 0;
  top: 0;
  color: $pagination-color;

  input {
    @include border-radius($border-radius-base);
    width: 40px;
    border: none;
    margin-left: 5px;
    padding: 2px 5px;
    text-align: center;
  }
}