  .promo-top {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid $brand-primary;
    color: $black;
    padding: 20px;
    text-align: center;
  }
  .promo-top h1 {
    font-size: 28px;
  }
  .promo-top h2 {
  }
  .promo-top h2 strong {
    font-size: 30px;
  }
  .promo-top h2 small {
    color: $black !important;
  }
  .promo-badge {
    background: $brand-secondary none repeat scroll 0 0;
    border: 2px solid $white;
    border-radius: 50%;
    color: $white;
    font-size: 30px;
    height: 80px;
    left: 17px;
    letter-spacing: -3px;
    line-height: 77px;
    position: absolute;
    text-align: center;
    top: -44px;
    width: 80px;
  }
  .promo-badge-2 {
    left: auto;
    right: 10px;
    top: 10px;
  }
  .btn-promo {
    background: $brand-secondary;
    border: 2px solid $white;
    color: white;
    font-size: 20px;
    margin-top: 10px;
    padding: 10px;
    white-space: normal;
  }
  .promo-content {
    border-left: 1px solid $grey-neutral;
    border-right: 1px solid $grey-neutral;
  }
  .promo-content h3 {
    background: $grey-neutral none repeat scroll 0 0;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }
  .promo-content ul {
  }
  .promo-content ul li {
    border-bottom: 1px solid $grey-neutral;
    font-size: 17px;
    padding: 16px;
    text-align: center;
  }
  .promo-content ul li:nth-of-type(2n+1) {
    background: $grey-neutral-light none repeat scroll 0 0;
  }
  .prom0-footer {
    background: $grey-neutral none repeat scroll 0 0;
    padding: 20px 20px 30px;
    text-align: center;
  }
  .prom0-footer h2, .prom0-footer h2 small {
    color: $black !important;
  }
  .prom0-footer ul {
    list-style: outside none none;
  }
  .prom0-footer ul li {
    display: inline-block;
    margin: 10px;
  }
  .prom0-footer ul li i {
    background: $white none repeat scroll 0 0;
    border: 3px solid $grey-neutral-light;
    border-radius: 50%;
    font-size: 20px;
    height: 50px;
    line-height: 44px;
    margin-bottom: 6px;
    margin-top: 10px;
    width: 50px;
  }
  .prom0-footer ul li h4 {
    font-weight: bold;
  }
  .prom0-footer ul li img {
    height: 71px;
    margin-bottom: 10px;
  }
  .promo-top.promo-pp {
    border: 1px solid $brand-info;
  }
  .promo-pp .btn-promo {
    background: $brand-info none repeat scroll 0 0;
  }
  .promo-top.promo-ent {
    border: 1px solid $brand-danger-dark;
  }
  .promo-ent .btn-promo {
    background: $brand-danger-dark none repeat scroll 0 0;
  }
  .promo-top.promo-free {
    border: 1px solid $grey-neutral;
  }
  .promo-free .btn-promo {
    background: $grey none repeat scroll 0 0;
  }
  .btn-promo {
    transition: all 0.3s ease 0s;
  }

  .who-we-are {
    border: 1px solid $grey-neutral;
    margin-bottom: 15px;
    padding: 20px;
    position: relative;
    text-align: center;
  }
  .who-we-are img.img-responsive {
    position: absolute;
    top:20px;
    right:20px
  }
  .who-we-are h3, .three-boxes h3, .who-we-are h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .who-we-are h4, .three-boxes h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .who-we-are p {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
  .my-little-decor {
    background: $brand-primary none repeat scroll 0 0;
    height: 4px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 60px;
  }

  @media (max-width: 1024px) {

    .who-we-are img.img-responsive {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      position: relative;
      margin-bottom:20px;
      right:0;
      top:0
    }

  }

  

  .registration-box {
    min-height: 500px;
  }

 /* landing page after registration */

  .register-background-pic {
    height: 100%;
    padding: 30px;
    background-size:cover
  }

  .register-background-pic-fl {
    background-image: url('/images/page_elements/registration/fl.jpg');
    background-position: right;
  }

  .register-background-pic-pp {
    background-image: url('/images/page_elements/registration/pp.jpg');
    background-position: center;
  }

  .registration .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    -webkit-flex-flow: row;
  }

  .registration .row-eq-height .col-sm-6 {

  }

  .register-body {
    padding: 30px;
    background: $white;
  }

  .arrows, .arrows li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .arrows {
    margin-bottom:10px
  }

  .arrows li {
    margin-left: 25px;
    margin-bottom: 6px
  }

  .arrows li:before {
    content: '\f105'; /* fa-angle-right */
    font-family: 'Font Awesome 5 Pro';
    float: left;
    margin-left: -1.5em;
    color: $brand-primary;
  }



  @media(max-width: $screen-md) {
    .register-background-pic {
      background-position: center
    }
  }

 .registration-outer {
    text-align: center;

    .col-md-4{
      max-width:420px;
      margin: 0 auto;
    }

    .registration-middle {
      border-left: 1px solid $brand-primary;
      border-right: 1px solid $brand-primary;
      padding: 20px;
      position: relative;
    }

    .registration-img {
      border: 1px solid $brand-primary;
      width: 100%;
    }

   .promo-top h1{
     font-size: 1.5rem;
    @include respond-to(xsmall){
        font-size: 1.75rem;
      }
    }
  }


