.fad {
  color: $brand-primary;
}

//circle imitating FA icon size
.icon-number{
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  margin: 4px 4px 4px 0;
  border-radius: 50%;
  background: $grey;
  color: $white;
  text-align: center;

  span{
    font-size: .875em;
    font-weight: 600;
    position: relative;
    top: -1px;
  }

  &.empty{
    background: $grey-neutral;
    color: $grey;
  }
}

.action-icons {
  text-align: right;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;

  .action-icon {
    font-size: .875rem;
    color: $grey;
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 20px;
    float: inline-start;

    &:hover,
    &:focus {
      cursor: pointer;
      color: $fl_primary_dark-40;
      background: $fl_gray_light-10;

      &.fa-ban,
      &.fa-times,
      &.fa-trash-alt,
      .fa-ban,
      .fa-trash-alt,
      .fa-times{
        color: $brand-danger;
      }
    }

    .bookmarked{
      color: $fl_accent_base;
      font-weight: 900;
    }
  }

  @media screen and (max-width:480px){
    max-width:124px;

    .action-icon {
      float: none;
    }
  }
}

@media screen and (min-width: 600px){
  .action-icons-overlap{
    max-width: calc(100% - 140px);
  }

  .action-icons-overlap-5{
    max-width: calc(100% - 170px);
  }
}


// Bootstrap toggle
[data-toggle="collapse"].collapsed .if-not-collapsed {
  display: none;
}
[data-toggle="collapse"]:not(.collapsed) .if-collapsed {
  display: none;
}


// Info icons
.fa-info-circle{
  font-size: .875em;
  color: $grey;
}

.fa-question-circle {
  font-size: .875em;
  color: $grey;
  opacity: .5;

  &:hover{
    color: $grey;
    opacity: 1;
  }
}

.cta-functional{
  .fa-info-circle,
  .fa-question-circle {
    color: inherit;
    opacity: 1;

    &:hover{
      color: inherit;
    }
  }
}

.collapsed {
  .fa-angle-up{
    transform: rotate(180deg);
  }
}
