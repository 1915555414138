/* notification box */

.notifcircle {
  background: $brand-secondary;
  width: 7px;
  height: 7px;
  display: block;
  text-align: center;
  border-radius: 50%;
  color: $white;
  position: absolute;
  font-size: 10px;
  top: 7px;
  left: 22px;
  line-height: 13px;
  font-weight: bold;
}

.notif-box {
  position: absolute;
  right: 0;
  left: auto;
  top: auto;
  border: 1px solid $grey-neutral;
  z-index: 100000000;
  background: $white!important;
  box-shadow: $shadow-primary!important;
  font-size:.875rem;

  @include respond-to(small){
    width: 400px;
  }
}

.notif-content {
  border-bottom: 1px solid $grey-neutral;
  height: 360px;
  overflow-y: scroll;
}

.notif-footer {
  padding: 15px 15px;
  background: $grey-neutral-light;
  font-size:.875rem;
}

.singleNote {
  border-bottom: 1px solid $grey-neutral;
  padding: 15px;
  position:relative
}

.singleNote i.fa-times {
  position: absolute;
  right: 15px;
  font-size: 11px;
  color: $grey;
  cursor: pointer
}

.singleNote > div {
  display: inline-block;
  width: calc(100% - 65px);
}

.singleNote img {
  background: $white;
  padding: 3px;
  border: 1px solid $grey-neutral;
  border-radius: 50%;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: top;
}

.singleNote a {
  color: $black;
  line-height: 16px;
  padding-bottom: 10px;
  display: block;
}

.singleNote a:hover {
  line-height: 16px;
  padding-bottom: 10px !important;
}

.singleNote small {
  display:block;
  color: $grey
}

.singleNote i.fa-times:hover {
  color: $brand-danger
}

.singleNote.unread {
  background: $grey-neutral-light
}

.singleNote.unread a,
.singleNote.unread a .semibold{
  font-weight:900;
}