html{
  font-size: $rem;
}

h1, .h1{
  font-size: 1.75rem;
  font-weight: 900;
  margin-bottom: .5rem;
}

h2, .h2{
  font-size: 1.625rem;
  font-weight: 900;
  margin:.5rem 0 .25rem;
}

h3, .h3{
  font-size: 1.5rem;
  font-weight: 900;
}

h4, .h4{
  font-size: 1.375rem;
  font-weight: 900;
}

h5, .h5{
  font-size: 1.25rem;
  font-weight: 900;
}

h6, .h6{
  font-size: 1rem;
  font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6{
  line-height: 1.1;

  a{
    color: $brand-primary;

    &:hover,
    &:focus {
      color: $brand-primary-dark;
      cursor:pointer;
      text-decoration: underline;
    }

    /*
    &:visited{
      color: $fl_primary_dark-20!important;
    }
    */
  }
}

.project-header h1,
.profile-header h1{
    font-size: 1.875rem;
}

@media screen and (max-width:480px) {
  .project-header h1,
  .profile-header h1,
  .list-item-main h3{
    font-size: 1.25rem;
  }
}

@media screen and (min-width:481px) and (max-width:767px) {
  .project-header h1,
  .profile-header h1,
  .list-item-main h3 {
    font-size: 1.5rem;
  }
}

p, .p{
  font-size: 1rem;
  margin: 0 0 calc($line-height-computed / 2);
  line-height: $line-height-base;
}

.p-margin p{
  margin-bottom: 10px;
}

.text-secondary{
  color: $fl-gray_base;
}

.text-example{
  color: $fl-gray_light-50;
}

//Promo text highlight
.text-promo {
  display: inline-block;
  background: $black;
  color: $white;
  padding: .25em .5em;
  font-weight: 400;
}

.text-promo-primary{
  color: $brand-secondary;
}

.text-promo-secondary{
  color: $brand-primary;
}

p a,
li a{
  color: $brand-primary;

  &.grey{
    color: $grey;
  }

  &:hover,
  &:focus{
    color: $fl_primary_dark-40;
  }
}

//CTA links
.cta-primary,
.cta-primary:focus{
  color: $black;
  background-image: linear-gradient(rgba( $fl-secondary-light-30, .6 ), rgba( $fl-secondary-light-30, .6 ));
  background-repeat: no-repeat;
  background-size: 150% 40%;
  background-position: 6px 100%;
  padding-right: 6px;
  transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);

  &:hover{
    color: $black;
    background-image: linear-gradient($fl-secondary-light-30, $fl-secondary_light-30);
    background-position: 0 100%;
    padding-right: 0;
  }
}

.cta-secondary,
.cta-secondary:focus {
  color: $brand-info;

  &:hover,
  &:focus {
    color: $brand-info-dark;
  }
}

.cta-functional,
.cta-functional:focus{
  font-size: .875em;
  cursor: pointer;
}


//Font sizes
.size-xs{font-size:.75rem;}

.size-sm{font-size:.875rem;}

.size-base{font-size:1rem!important;}

.size-lg{font-size:1.25rem;}

.size-xl{font-size:1.5rem;}

.size-smaller{font-size:.75em;}

.size-small{font-size:.875em;}

.size-big{font-size:1.25em;}

.size-bigger{font-size:1.5em;}


// LISTS
ul{
  li{
    margin-bottom: .5rem;

    &.indent-fix {
      padding-left: 1.25rem;
      text-indent: -1.25rem;
    }
  }

  &[class^="ul-"]{
    margin-left: 1rem;

    li::before{
      margin-left: -.5rem;
    }
  }

  &.ul-basic li::before{
      font-family: $font-icon;
      content:'\f111';
      font-size: 30%;
      color:$black;
      padding-right: .25rem;
      font-weight: 900;
      vertical-align: middle;
  }

  &.ul-arrow li::before{
      font-family: $font-icon;
      content:'\f105';
      font-size: .875em;
      color:$fl_primary_light-50;
      padding-right: .25rem;
      font-weight: 400;
  }

  &.ul-checkmark li::before{
      font-family: $font-icon;
      content:'\f00c';
      font-size: .875em;
      color:$fl_primary_light-50;
      padding-right: .25rem;
      font-weight: 400;
      margin-left: -1rem;
  }

  &.ul-2-col-xs{
    @include respond-to(xsmall) {
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
    }
  }

  &.ul-2-col-sm{
    @include respond-to(small) {
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
    }
  }
}


// MISC
.semibold{
  font-weight: 600;
}

.bold,
b,
strong {
  font-weight: 900;
}

.small-uppercase{
  color: $grey;
  font-size: .875rem;
  text-transform: uppercase;
}

.footnote{
  font-size: .75rem;
  padding-top: 1rem;
  margin-top: 1.5rem;

  p{
    font-size: .75rem;
  }
}

.footnote-border{
  @extend .footnote;
  border-top: 1px solid $grey-neutral;
}

.highlight {
  background-color: $brand-info-light;
  color: $black;
  padding-left: 4px;
  padding-right: 4px;
}

.profile-tag{
  display: inline-block;
  padding: 4px 8px;
  font-size: .75rem;
  color: $grey;
  background: $grey-neutral-light;
  border-radius: 999px;
  cursor: default;
  margin:0 4px 4px 0;

  div {
    display: inline-block;
    font-size: 12px;
    padding: 2px 4px 0;
    background: $white;
    border-radius: 50%;
  }

  &.tag-primary{
    background: $brand-primary-light;
  }
}

.seo-tags{
  .profile-tag{
    display: inline-block;
    text-align: center;
    margin: 4px 2px;

    &.single-char{
      min-width: 1.675rem;
      height: 1.675rem;
    }

    a{
      color: $grey;
      font-size: .875rem;
      white-space: nowrap;
    }

    &:hover,
    &:focus{
      background-color: $grey-lighter;
      cursor: pointer;

      a{
        color: $black;
      }
    }
  }
}

code {
  color: $black;
  background-color: $grey-neutral-light;
}

.tooltip {
  position: fixed;
}

.regular-type{
  font-weight: 400!important;
}

.bg-primary-light a.cta-functional,
.bg-primary-light li a {
  color: $fl_primary_dark-20;

  &:hover,
  &:focus{
    color: $fl_primary_dark-40;
  }
}

//Breadcrumbs
.breadcrumb-box{
  font-size: .75rem;
  margin-bottom: .5rem;
  color: $grey;

  a{
    color: $grey;

    &:hover,
    &:focus{
      color: $fl_primary_dark-60;
    }
  }
}

.bg-primary-light .breadcrumb-box{
  color: $black;

  a{
    color: $black;

    &:hover,
    &:focus{
      color: $fl_primary_dark-60;
    }
  }
}

//RESPONSIVE HELPERS
@include respond-to(small){
  .text-right-sm{
    text-align: right!important;
  }
}

@include respond-to(medium){
  .text-right-md{
    text-align: right!important;
  }
}