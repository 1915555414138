.login-status {
  margin-top: $line-height-computed;
  text-align: center;

  @include respond-to(medium) {
    float: right;
    display: inline-block;
    margin-top: -$line-height-computed;
  }

  a {
    color: $black;

    &:hover {
      color: $brand-primary;
    }
  }
}

.login-user {
  @extend %clearfix;
  @include border-radius($border-radius-base);
  margin-bottom: 20px;
  width: $menu-width;

  .menu-minimized & {
    width: $menu-width-small;
  }
}

.login-greeting {
  color: $grey-neutral-light;
}

.login-user-image {
  @include border-radius($border-radius-base 0 0 $border-radius-base);
  float: left;
  margin-right: 5px;

  .menu-minimized & {
    @include border-radius($border-radius-base);
  }
}

.login-user-name {
  @include border-radius($border-radius-base);
  font-size: 10px;
  line-height: 1;
  background-color: $grey;
  height: 36px;
  color: $white;
  padding: 3px;

  .menu-minimized & {
    display: none;
  }
}