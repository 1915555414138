.panel{
  position: relative;
  background-color: $white;
  margin-bottom: $line-height-computed*2;
  border-radius: 0;
  box-shadow: $shadow-primary;

  & > .panel-heading{
    //background-color: $white;
    background-image: linear-gradient(to right, $fl_primary_light-5, $white);
    color: $black;
    border-radius: 0;
    border:none;
    padding: .5rem .75rem;

    h3, h2{
      //color: $grey;
      font-size: 1rem;
      font-weight: 600;
      display: inline-block;
      margin: 0;
      //text-transform: uppercase;
    }

    &.panel-heading-state {
      padding: .25rem 1rem;

      &.panel-heading-success{
        background: $brand-success-light;
      }

      &.panel-heading-danger{
        background: $brand-danger-light;
      }

      .action-icons{
        position: relative;
        margin-bottom:.25rem;
        top: 2px;

        .action-icon{
          color: $black;
          float: none;
          width: 24px;
          height: 24px;
          line-height: 24px;

          &:hover{
            color: $fl_primary_dark-60;
          }
        }

        @media screen and (min-width: 600px){
          position: absolute;
        }
      }
    }
  }

  .panel-footer{
    border-radius: 0;
  }

} // .panel end


// ADDITIONAL PANEL STYLES
.panel-grey{
  background-color: $grey-neutral-light;

  & > .panel-heading {
    background-color: $grey-neutral-light;
  }
}

.panel-invisible{
  background-color: transparent;
  box-shadow:none;

  & > .panel-heading {
    background-color: transparent;
    background-image: none;
  }
}

.panel-promo-primary {
  background-color: $brand-primary;
}

.panel-promo-black {
  background-color: $black;
  color: $white;
}

.panel-outline-primary {
  border: 2px solid $brand-primary;
  box-shadow: none;
}

.panel-outline-black {
  border: 2px solid $black;
  box-shadow: none;
}

.panel-outline-grey {
  border: 2px solid $grey-neutral;
  box-shadow: none;
}

.panel-register {
  & > .panel-heading {
    background-image: none;
    padding-bottom: .75rem;

    h1 {
      padding: .5rem 0 1rem;
      border-bottom: 3px solid $black;
      font-weight: 400;
    }
  }

  @include respond-to(large) {
    .panel-heading,
    .panel-body {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
// PANEL GROUP
.panel-group {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }

  .panel-heading + .panel-collapse > .panel-body {
    border: none;
  }

  .panel {
    border-radius: 0;
  }

  .panel-heading{
    @extend %clearfix;
    border-radius: 0;
    box-shadow: none;
    //padding:.5rem .75rem;
    display: block;
    color: $black;

    &:hover{
      //background-color: $white;
      color: $fl_primary_dark-60;

      h3 {
        color:$fl_primary_dark_60;
      }
    }

    .set-editable {
      display: inline-block;
    }

    + .section-tags{
      display: none;
    }

    &.collapsed {
      .set-editable {
        display: none;
      }

      i.fa-angle-up {
        transform: rotate(180deg);
      }

      + .section-tags{
        display: inline-block;
      }
    }
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.panel,
.panel-group {
  .panel-inner {
    box-shadow: none;
    margin: 0 0 5px 0;
    padding-bottom: 5px;

    &:not(:last-of-type) {
      border-bottom: 1px dashed $grey-neutral;
    }
  }
}

// list view search hit
.panel-highlight-match {
  color: $black;
  font-size: .875rem;
  text-align: left;
  padding: .375rem 1rem .5rem;
  border-top: 1px solid $grey-neutral;
}

.certificate_class{
  border-left: 5px solid $brand-primary;
}



@include respond-to(small) {
  .row-eq-height [class^="col-"]{
    margin-bottom:1.5rem;

    .panel {
      margin-bottom: 0;
      height: 100%;

      &.no-stretch {
        height: auto;

        &:not(:last-child) {
          margin-bottom: $line-height-computed;
        }
      }
    }
  }
}

@include respond-to(medium) {
  .row-eq-height-md [class^="col-"]{
    margin-bottom:1.5rem;

    .panel {
      margin-bottom: 0;
      height: 100%;

      &.no-stretch {
        height: auto;

        &:not(:last-child) {
          margin-bottom: $line-height-computed;
        }
      }
    }
  }
}


// Tabs
.nav-tabs {
  border: none;
  padding: 0;

  > li {
    float: none;
    margin-bottom: .25rem;
    background: $black;

    > a {
      color: $white;
      padding: .5rem;
      line-height: 1;
      border-radius: 0;
      border: none;
      border-bottom: 4px solid $black;
      margin: 0;

      &:hover {
        background: $black;
        color: $white;
        border-bottom-color: $brand-primary-light;
      }
    }

    &.active > a {
      &,
      &:hover,
      &:focus {
        background: $white;
        color: $black;
        font-weight: 600;
        border: none;
        border-bottom: 4px solid $brand-primary-light;
      }
    }
  }

  @media (min-width: 768px) {
    border-bottom: 1px solid $nav-tabs-border-color;
    padding: 0 .5rem;

    > li {
      float: left;
      background: transparent;
      margin-bottom: -2px;

      > a {
        color: $black;
        border-bottom-color: transparent;

        &:hover {
          background: $white;
          color: $fl_primary_dark-60;
          border-bottom-color: $brand-primary-light;
        }
      }
    }
  }
}

.tab-content{
  ul a{
    color: $grey;

    &:hover{
      color: $fl_primary_dark-60;
    }
  }
}

.form-hint{
  padding: 1rem;
  background-color: $fl_gray_light_5;
  color: $fl_gray_base;
  border-radius: $input-border-radius;
  margin-bottom: .75rem;

  @include respond-to(small){
    margin-top: 23px;
  }
}

.contact-chat{
  .bg-primary-light::before{
    content: "";
    display: block;
    position: absolute;
    left: -6px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: $fl_primary_light-30 transparent transparent transparent;
  }

  .btn{
    width: 50px;
    height: 50px;
    border-radius: 70px;
  }
}