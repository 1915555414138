.hide-if-empty:empty{
  display: none;
}

// color classes
@each $label, $color in $colours {
  .fg-#{$label} {
    color: $color !important;
  }

  .bg-#{$label} {
    background-color: $color !important;
  }

  .fg-hover-#{$label}:hover {
    color: $color !important;
  }

  .bg-hover-#{$label}:hover {
    background-color: $color !important;
  }

  .border-color-#{$label} {
    border-color: $color !important;
  }
}

// gap classes
@each $label, $gap in $gaps {
  .margin-#{$label} {
    margin: $gap !important;
  }

  .margin-right-#{$label} {
    margin-right: $gap !important;
  }

  .margin-left-#{$label} {
    margin-left: $gap !important;
  }

  .margin-top-#{$label} {
    margin-top: $gap !important;
  }

  .margin-bottom-#{$label} {
    margin-bottom: $gap !important;
  }

  .padding-#{$label} {
    padding: $gap !important;
  }

  .padding-right-#{$label} {
    padding-right: $gap !important;
  }

  .padding-left-#{$label} {
    padding-left: $gap !important;
  }

  .padding-top-#{$label} {
    padding-top: $gap !important;
  }

  .padding-bottom-#{$label} {
    padding-bottom: $gap !important;
  }
}

.fw-200{
  width:200px!important;
}

.pull-bottom{
  position: absolute !important;
  bottom: 0 !important;
}

.full-width{
  width: 100% !important;
}


@include respond-to(small){
  .pull-sm-left{
    float: left!important;
  }

  .pull-sm-right{
    float: right!important;
  }

  .pull-sm-none{
    float: none!important;
  }

  .no-padding-right-sm{
    padding-right: 0!important;
  }

  .no-padding-left-sm{
    padding-left: 0!important;;
  }
}

@include respond-to(medium){
  .pull-md-left{
    float: left!important;
  }

  .pull-md-right{
    float: right!important;
  }
}

@include respond-to(large){
  .pull-lg-left{
    float: left!important;
  }

  .pull-lg-right{
    float: right!important;
  }
}


//Bootstrap hidden classes fix
@media (max-width: 479px) {
  .hidden-xxs {
    display: none !important;
  }
}
@media (max-width: 767.99px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991.99px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.99px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
